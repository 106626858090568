import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'

let config = {}

export const setupFirebase = () => {
  if (process.env.REACT_APP_API_ENV === 'production') {
    console.log('[production] -> firebase')
    config = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: 'fe-guarantor.firebaseapp.com',
      databaseURL: 'https://fe-guarantor.firebaseio.com',
      projectId: 'fe-guarantor',
      storageBucket: 'fe-guarantor.appspot.com',
      messagingSenderId: '633954820248',
      appId: '1:633954820248:web:d6823c796ac96f64e030ed',
      measurementId: 'G-KPTNXYVQXP'
    }
  } else {
    console.log('[development] -> firebase')
    config = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: 'fe-guarantor-dev.firebaseapp.com',
      databaseURL: 'https://fe-guarantor-dev.firebaseio.com',
      projectId: 'fe-guarantor-dev',
      storageBucket: 'fe-guarantor-dev.appspot.com',
      messagingSenderId: '158215936220',
      appId: '1:158215936220:web:c800598c78a9832357fc19',
      measurementId: 'G-BDJD5Z4ZXW'
    }
  }

  firebase.initializeApp(config)

  firebase.auth().signInAnonymously()

  return firebase
}

export default firebase
