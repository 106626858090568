import React from 'react'
import PropTypes from 'prop-types'

import { useScreenMeasure } from '~/utils'
import { TemplateContext } from './context'
import { useSendingInfo, useHelpModal, useHeader, useToken, useInfos, useSentryInfos } from './hooks'

export const TemplateProvider = props => {
  const { children } = props
  const [isLoading, goToPreviousScreen, setIsLoading, isLoadingGlobal, modal, setModal, goToPage] = useSendingInfo()
  const [isShowModal, handleModal] = useHelpModal()
  const [isMobile] = useScreenMeasure()
  const [setCurrent, options, current] = useHeader()
  const [token, setToken, checkToken] = useToken()
  const [infosSentry, setInfosSentry] = useSentryInfos()

  const [creditRequestInfo, setCreditRequestInfo, ip, setIp, guarantorInfo, setGuarantorInfo] = useInfos()

  const value = {
    goToPreviousScreen,
    isLoading,
    setIsLoading,
    isLoadingGlobal,
    modal,
    setModal,
    goToPage,
    //
    isShowModal,
    handleModal,
    isMobile,
    options,
    setCurrent,
    current,
    token,
    setToken,
    checkToken,

    creditRequestInfo,
    setCreditRequestInfo,
    ip,
    setIp,
    guarantorInfo,
    setGuarantorInfo,
    //
    //
    infosSentry,
    setInfosSentry
  }

  return <TemplateContext.Provider value={value}>{children}</TemplateContext.Provider>
}

TemplateProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired
}
