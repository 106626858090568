import axios from 'axios'
import * as Sentry from '@sentry/browser'
import { ROOT_URL } from '../constants'

export const getTypeArchive = body => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(ROOT_URL.workerUpload, body)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: `ERROR: ${err}`,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}
