import React, { useContext } from 'react'
import { Button, Container, Title } from '@provi/provi-components'
import { TemplateContext } from '~/components/template/context'

import { Modal } from './modal'
import { useDocuments } from './hooks'
import { Text, Bold, WrapperTitle } from './styles'

export const Documents = () => {
  const { setIsLoading, setCurrent, goToPage } = useContext(TemplateContext)
  const [handlePage, setIsShowModal, isShowModal] = useDocuments(setIsLoading, setCurrent, goToPage)

  return (
    <>
      {isShowModal && <Modal setIsShowModal={setIsShowModal} />}
      <Container isMarginMobile>
        <WrapperTitle>
          <Title marginVertical={0} text="Próximos passos:" />
        </WrapperTitle>
        <Text>
          <Bold>Chegamos na reta final da sua solicitação!</Bold>
          <br /> Para continuar, é importante que você tenha em mãos o seu <Bold>RG ou CNH</Bold>
        </Text>

        <Button
          id="data-safety-button"
          marginHorizontal={0}
          text="Avançar"
          onClick={handlePage}
          textLink={{ text: 'Meus dados estão seguros?', onClick: () => setIsShowModal(true) }}
        />
      </Container>
    </>
  )
}
