import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'

import ErrorBoundary from './components/error-boundary'
import { TemplateProvider } from './components/template/provider'
import { Navigation } from './navigation'
import { HelmetWrapper } from './components/helmet'

import './config'
import './style.css'
import '../node_modules/react-input-range/lib/css/index.css'

localStorage.removeItem('asset-ref')

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <ErrorBoundary>
        <TemplateProvider>
          <HelmetWrapper />
          <Navigation />
        </TemplateProvider>
      </ErrorBoundary>
    </AppContainer>,
    document.getElementById('provi-root')
  )
}

render()

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept('./navigation', () => {
    render()
  })
}
