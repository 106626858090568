import { useEffect, useState } from 'react'
import cep from 'cep-promise'
import { STATES } from '~/constants'
import { getGuarantorInfo } from '~/services/api'
import { toLower, showAllToasts } from '~/utils'

export const useCep = (setIsLoading, setCurrent, checkToken, guarantorInfo) => {
  const [data, setData] = useState(undefined)
  const [isCep, setIsCep] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    checkToken()
    setCurrent(20, 2)

    const getData = async () => {
      try {
        let response
        if (guarantorInfo && guarantorInfo.address) {
          response = { data: { ...guarantorInfo } }
        } else {
          response = await getGuarantorInfo('/cep')
        }

        const { address } = response.data

        let addressObj

        if (address) {
          const { number, complement, state, city, zipcode, district, street } = address

          if (zipcode) setIsCep(true)

          addressObj = {
            cep: zipcode,
            city,
            state,
            district,
            street,
            number,
            complement
          }
        }

        setData({
          ...addressObj
        })

        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        showAllToasts(error)
      }
    }
    getData()
  }, [])

  return [data, isCep]
}

export const usePersonalDataValidator = (setValues, values, dataProps) => {
  const [isShowAddress, setIsShowAddress] = useState(false)
  const [selectError, setSelectError] = useState(true)
  const [selectErrorBlur, setSelectErrorBlur] = useState()
  const [selectedOption, setSelectedOption] = useState()

  const setSelect = select => {
    setSelectedOption(select)
    setSelectError(false)
    setValues({ ...values, state: select.label })
  }

  useEffect(() => {
    if (dataProps && dataProps.state) {
      const stateSelectData = STATES.find(st => toLower(st.label) === toLower(dataProps.state))
      setSelect(stateSelectData)
    }
  }, [])

  const getCep = async zipcode => {
    setIsShowAddress(true)

    const data = await cep(zipcode)
    return {
      zipcode,
      state: data.state || '',
      city: data.city || '',
      district: data.neighborhood || '',
      street: data.street || '',
      number: '',
      complement: ''
    }
  }

  const selectBlur = () => {
    if (!selectedOption) {
      setSelectErrorBlur(true)
    } else {
      setSelectErrorBlur(false)
    }
  }

  return [isShowAddress, getCep, selectError, setSelect, selectedOption, selectBlur, selectErrorBlur]
}

export const useForm = () => {
  const [isShowModal, setIsShowModal] = useState(false)
  const [modal, setModal] = useState()

  const handleModal = (bool, modalText) => {
    setIsShowModal(bool)
    setModal(modalText)
  }

  return [isShowModal, setIsShowModal, handleModal, modal]
}
