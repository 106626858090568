/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { Background } from '../error-boundary-background'
import { logger } from '~/helpers/logger'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    logger({ error, info, screen: '(ErrorBoundary)' })
  }

  render() {
    const { hasError } = this.state
    const { history, children } = this.props
    if (hasError) {
      return <Background history={history} />
    }

    return children
  }
}

export default ErrorBoundary
