import React, { useContext, useEffect } from 'react'
import { Container, Title } from '@provi/provi-components'
import { TemplateContext } from '~/components/template/context'

import { useDocuments } from './hooks'
import { WrapperTitle, OptionsList, Option } from './styles'

export const PersonalOption = () => {
  const { setIsLoading, isMobile, setCurrent, goToPage } = useContext(TemplateContext)
  const [handlePage, setSelectedOption, selectedOption] = useDocuments(setIsLoading, setCurrent, goToPage)

  useEffect(() => {
    selectedOption && handlePage()
  }, [selectedOption])

  return (
    <>
      <Container>
        <WrapperTitle>
          <Title marginVertical={0} text="Escolha abaixo o documento que você quer enviar" />
        </WrapperTitle>
        <OptionsList isMobile={isMobile}>
          <Option isSelected={selectedOption === 'rg'} onClick={() => setSelectedOption('rg')}>
            RG
          </Option>
          <Option isSelected={selectedOption === 'cnh'} onClick={() => setSelectedOption('cnh')}>
            CNH
          </Option>
        </OptionsList>
      </Container>
    </>
  )
}
