import axios from 'axios'
import * as Sentry from '@sentry/browser'
import { history } from '~/navigation/history'
import { ROOT_URL, ERRORS } from '../constants'
import { asyncLocalStorage } from '~/utils'
import { logger } from '~/helpers/logger'
import firebase from '~/services/firebase'
import { showToast } from '~/components/toast'

export const api = axios.create({
  baseURL: ROOT_URL.guarantor
})

const getToken = () => {
  return asyncLocalStorage.getItem('token')
}

const transactionId = Math.random()
  .toString(36)
  .substr(2, 9)

api.interceptors.request.use(
  async config => {
    const token = await getToken()
    if (token) {
      Sentry.configureScope(function(scope) {
        scope.setTag('x-request-id', transactionId)
      })
      config.headers.authorization = token
      config.headers['x-request-id'] = transactionId
    }
    return config
  },
  error => {
    if (error.response.status === 403) {
      history.push('/token')
    } else {
      return Promise.reject(error)
    }
  }
)

const checkIfNotAuthorized = errorMessage => {
  if ([ERRORS.auth.invalidToken, ERRORS.auth.expiredToken, ERRORS.auth.notAuthorized].includes(errorMessage)) {
    localStorage.removeItem('token')
    history.push('/token')
  }
}

const treatError = (error, shouldCheckIfNotAuthorized = true) => {
  if (shouldCheckIfNotAuthorized) {
    const errorsArray = error?.response?.data?.errors
    if (['array', 'object'].includes(typeof errorsArray)) {
      for (const err in errorsArray) {
        checkIfNotAuthorized(err.message)
      }
    }
  }

  Sentry.addBreadcrumb({
    category: 'errorRequest',
    message: `ERROR: ${error}`,
    level: Sentry.Severity.Warning
  })

  throw error
}

// events

export const getAccessToken = token => {
  try {
    return api.get(`/public-token/${token}`)
  } catch (error) {
    treatError(error, false)
  }
}

export const authToken = (cpf, urlToken) => {
  try {
    return api.post('/public-token', {
      cpf,
      publicToken: urlToken
    })
  } catch (error) {
    treatError(error)
  }
}

export const getCreditRequestInfo = () => {
  try {
    return api.get('/credit-request-info')
  } catch (error) {
    treatError(error)
  }
}

export const getGuarantorInfo = screen => {
  try {
    return api.get('/guarantor-info')
  } catch (error) {
    logger({ error, screen })
    treatError(error)
  }
}

export const getPersonalDocument = (type, side) => {
  try {
    let urlParams
    if (side) {
      urlParams = `documentType=${type}&photoSide=${side}`
    } else {
      urlParams = `documentType=${type}`
    }
    return api.get(`/personal-document?${urlParams}`)
  } catch (error) {
    treatError(error)
  }
}

export const putGuarantorInfo = async data => {
  try {
    const { email, name, phone, number, complement, state, city, zipcode, district, street, birthDate } = data

    let addressObj = {}
    if (number && state && city && zipcode && district && street) {
      addressObj = {
        address: {
          number,
          complement,
          state,
          city,
          zipcode,
          district,
          street
        }
      }
    }

    return await api.put('/guarantor-info', {
      email,
      fullName: name,
      phone,
      ...addressObj,
      birthDate
    })
  } catch (error) {
    treatError(error)
  }
}

export const postSendEmail = ({ resend = false }) => {
  try {
    const endpoint = resend ? '/email/resend' : '/email/send'
    return api.post(endpoint)
  } catch (error) {
    error.response.status !== 403 && logger({ error, screen: '(fn postSendEmail)' })
    treatError(error)
  }
}

export const postSendPhone = () => {
  try {
    return api.post('/phone/send')
  } catch (error) {
    treatError(error)
  }
}

export const postVerifyPhone = data => {
  try {
    return api.post('/phone/verify', {
      data
    })
  } catch (error) {
    treatError(error)
  }
}

export const postPersonalDocument = data => {
  try {
    return api.post('/personal-document', data)
  } catch (error) {
    treatError(error)
  }
}

export const getProofOfAddress = () => {
  try {
    return api.get('/proof-of-address')
  } catch (error) {
    treatError(error)
  }
}

export const postProofOfAddress = data => {
  try {
    return api.post('/proof-of-address', {
      downloadURL: data
    })
  } catch (error) {
    logger({ error, screen: '/email' })
    treatError(error)
  }
}

export const getSelfie = () => {
  try {
    return api.get('/selfie')
  } catch (error) {
    treatError(error)
  }
}

export const postSelfie = data => {
  try {
    return api.post('/selfie', {
      downloadURL: data
    })
  } catch (error) {
    treatError(error)
  }
}

export const acceptedTermsAndConditions = async ip => {
  try {
    if (ip) {
      await api.put('/credit-request-info', {
        ip
      })
    } else {
      const { data } = await axios.get('https://api.ipify.org/?format=json')

      await api.put('/credit-request-info', {
        ip: data.ip
      })
    }
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR: ${error}`,
      level: Sentry.Severity.Warning
    })
  }
}

export const uploadImages = async (location, array, asset = null) => {
  const result = []
  if (array.length > 0) {
    try {
      const response = await getCreditRequestInfo()
      const { creditRequest } = response.data

      await Promise.all(
        array.map(async item => {
          const now = new Date().getTime()
          const storagePath = `/${creditRequest.id}/${now}`
          const uploadRef = firebase.storage().ref(storagePath)
          await uploadRef.put(item)
          const url = await uploadRef.getDownloadURL()

          if (!url || typeof url !== 'string') {
            logger({
              error: `Erro ao subir ${location} para o firebase, crid: ${creditRequest.id}`
            })
            Sentry.captureException(new Error(`Erro ao subir ${location} para o firebase, crid: ${creditRequest.id}`))

            throw new Error(`Erro ao subir ${location} para o firebase, crid: ${creditRequest.id}`)
          }

          if (asset) {
            result.push({ data: url, type: asset, typeExtension: item.type })
          } else {
            result.push({ data: url, typeExtension: item.type })
          }
        })
      )
    } catch (error) {
      showToast(error && error.message)
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: `ERROR: ${error}`,
        level: Sentry.Severity.Warning
      })
    }
  }
  return result
}
