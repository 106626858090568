import React from 'react'

export const IconVideo = () => (
  <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23 3L16 8L23 13V3Z" stroke="#112752" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M3 1H14C15.1 1 16 1.9 16 3V13C16 14.1 15.1 15 14 15H3C1.9 15 1 14.1 1 13V3C1 1.9 1.9 1 3 1Z"
      stroke="#112752"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
