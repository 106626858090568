import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal as ModalComponent } from '@provi/provi-components'
import { Text } from './style'

const URL_EXTERNAL = 'https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20?'

export const Modal = ({ setIsShowModal }) => {
  const data = {
    title: 'Não sei meu CEP',
    emoji: '😞',
    text:
      'Algumas ruas não possuem endereçamento postal, e esse pode ser o seu caso! Se sua rua não tem CEP, por favor, entre em contato conosco no nosso Whatsapp, e te ajudaremos a encontrar!',
    textButton: 'Abrir chat'
  }

  return (
    <ModalComponent
      title={data.title}
      emoji=":/"
      onDismiss={() => setIsShowModal(false)}
      footer={
        data.textButton && <Button type="button" text={data.textButton} onClick={() => window.open(URL_EXTERNAL, '_blank')} />
      }
    >
      <Text>{data.text}</Text>
    </ModalComponent>
  )
}

Modal.propTypes = {
  setIsShowModal: PropTypes.func.isRequired
}
