import React from 'react'

export const IconPicture = () => (
  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 1H1V20.6H19V1Z" stroke="#112752" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M16.2 4.10001H3.80005V14.9H16.2V4.10001Z"
      stroke="#112752"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
