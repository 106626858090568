import React from 'react'
import Helmet from 'react-helmet'

export const HelmetWrapper = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
    </Helmet>
  )
}
