import React from 'react'
import { Redirect, Switch, Router } from 'react-router-dom'
import Route from './Route'
import { ToastComponent } from '../components/toast'
import { routes } from './routes'
import { history } from './history'

export const Navigation = () => {
  return (
    <Router history={history}>
      <Switch>
        {routes.map(item => (
          <Route
            key={item.key}
            exact={item.exact}
            path={item.path}
            isPrivate={item.private}
            isFirstScreen={item.isFirstScreen}
            isHeader={item.isHeader}
            component={item.component}
            isTemplate={item.isTemplate}
          />
        ))}
        <Redirect to="/token" exact />
      </Switch>
      <ToastComponent />
    </Router>
  )
}
