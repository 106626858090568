import React from 'react'

import * as Yup from 'yup'
import { withFormik } from 'formik'
import PropTypes from 'prop-types'

import { Button, Input, Form } from '@provi/provi-components'

import { TextErrorInput } from '~/components/text-error-input'

import { Section } from './style'
import { putGuarantorInfo } from '~/services/api'
import { logger } from '~/helpers/logger'
import { showAllToasts } from '~/utils'

export const Formik = props => {
  const { handleChange, values, errors, touched, handleSubmit, handleBlur } = props

  return (
    <Form onSubmit={handleSubmit}>
      <Section>
        <Input
          id="email"
          InputProps={{ 'aria-label': 'email', cy: 'input-email' }}
          placeholder="Digite aqui seu melhor email"
          onBlur={handleBlur('email')}
          onChange={handleChange('email')}
          value={values.email}
        />
        {errors.email && touched.email && <TextErrorInput>{errors.email}</TextErrorInput>}
      </Section>

      <Button
        marginHorizontal={0}
        marginVertical={24}
        cy="button"
        type="submit"
        disabled={!values.email || errors.email}
        text="Avançar"
      />
    </Form>
  )
}
export const FormFormik = withFormik({
  mapPropsToValues: props => {
    const { data } = props
    return {
      email: data.email ? data.email : ''
    }
  },
  validationSchema: () =>
    Yup.object().shape({
      email: Yup.string()
        .trim()
        .email('Digite um email válido')
        .required('Preencha o campo email')
    }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { setIsLoading, setGuarantorInfo, data, goToPage } = props
    const { email } = values
    // data

    setSubmitting(false)

    try {
      setIsLoading(true)

      try {
        setGuarantorInfo(oldValues => ({ ...oldValues, ...values, firstName: data.firstName }))
      } catch (error) {
        logger({ error, ref: 'cache context (email)' })
      }

      await putGuarantorInfo({ email: email.trim() }, '/email')

      goToPage('/verificacao-email')
    } catch (error) {
      setIsLoading(false)
      showAllToasts(error)
    }
  }
})(Formik)

Formik.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.string).isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  touched: PropTypes.oneOfType([PropTypes.any]).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired
}
