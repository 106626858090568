import React from 'react'

import * as Yup from 'yup'
import { withFormik } from 'formik'
import PropTypes from 'prop-types'

import { Button, InputMasks, Form } from '@provi/provi-components'

import { TextErrorInput } from '~/components/text-error-input'

import { Section } from './style'
import { putGuarantorInfo } from '~/services/api'
import { logger } from '~/helpers/logger'
import { validatePhone } from '~/utils/validators'
import { showAllToasts } from '~/utils'

export const Formik = props => {
  const { handleChange, values, errors, touched, handleSubmit, handleBlur } = props

  return (
    <Form onSubmit={handleSubmit}>
      <Section>
        <InputMasks
          type="phone"
          inputmode="numeric"
          id="phone"
          InputProps={{ 'aria-label': 'phone', cy: 'input-phone' }}
          mask="(99) 99999-9999"
          placeholder="Ex: (11) 95771-2414"
          onBlur={handleBlur('phone')}
          onChange={handleChange('phone')}
          value={values.phone}
        />
        {errors.phone && touched.phone && <TextErrorInput>{errors.phone}</TextErrorInput>}
      </Section>

      <Button
        marginHorizontal={0}
        marginVertical={24}
        cy="button"
        type="submit"
        disabled={!values.phone || errors.phone}
        text="Avançar"
      />
    </Form>
  )
}
export const FormFormik = withFormik({
  // isInitialValid: false,
  mapPropsToValues: props => {
    const { data } = props
    return {
      phone: data.phone ? data.phone : ''
    }
  },
  validationSchema: () =>
    Yup.object().shape({
      phone: Yup.string()
        .test({
          name: 'phone',
          message: 'Telefone inválido',
          test: phone => phone && validatePhone(phone)
        })
        .required('Preencha o seu Celular')
    }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { setIsLoading, setGuarantorInfo, goToPage } = props
    setSubmitting(false)

    try {
      try {
        setGuarantorInfo(oldValues => ({ ...oldValues, ...values }))
      } catch (error) {
        logger({ error, ref: 'cache context (phone)' })
      }

      setIsLoading(true)
      await putGuarantorInfo(values, '/phone')

      goToPage('/verificacao-telefone')
    } catch (err) {
      setIsLoading(false)
      showAllToasts(err)
    }
  }
})(Formik)

Formik.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.string).isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  touched: PropTypes.oneOfType([PropTypes.any]).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired
}
