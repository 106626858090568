import styled from 'styled-components'
import { theme } from '@provi/provi-components'

import background_login from '~/assets/svg/background-desktop-login.svg'

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  background-color: ${theme.colors.blue900};
  overflow-y: scroll;
`

export const ContainerIntro = styled.div`
  width: 65%;
  height: 100%;
  background-color: ${theme.colors.blue900};
  display: flex;
  justify-content: center;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    padding: 20px;
    justify-content: space-around;
    box-sizing: border-box;
  }
`

export const Content = styled.div`
  max-width: 600px;
  margin-top: 104px;
`

export const Text = styled.div`
  font-family: 'CenturyGothic', sans-serif;
  font-size: 16px;
  line-height: 30px;

  margin-bottom: 30px;
  color: ${({ isDark }) => (isDark ? theme.colors.white100 : theme.colors.blue900)};

  font-style: normal;
  font-weight: normal;

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
  }
`

export const ContainerBackground = styled.div`
  width: 35%;
  background: url(${background_login});
  background-color: ${theme.colors.blue900};
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 100%;
`

export const ModalText = styled.span`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
  color: ${({ isDark }) => (isDark ? theme.colors.white100 : theme.colors.blue900)};
`

export const Container = styled.div`
  .MuiInput-underline:after {
    border-bottom: 1px solid #ffffff;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid #ffffff;
  }
`
