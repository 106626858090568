import { useEffect, useState } from 'react'
import { getCreditRequestInfo, acceptedTermsAndConditions } from '~/services/api'
import { capitalize, formatDate, formatValue, formatCpf, formatPercent, showAllToasts } from '~/utils'
import { showToast } from '~/components/toast'
import { ERRORS } from '~/constants'

export const useResume = (setIsLoading, setCurrent, creditRequestInfo, setCreditRequestInfo, ip, checkToken, goToPage) => {
  const [data, setData] = useState()

  useEffect(() => {
    setIsLoading(true)
    checkToken()

    setCurrent(6.66, 1)

    const getData = async () => {
      try {
        let response

        if (!creditRequestInfo) {
          response = await getCreditRequestInfo('/resumo')
          setCreditRequestInfo(response.data)
        } else {
          response = { data: creditRequestInfo }
        }

        const { user, guarantor, creditRequest, product } = response.data

        const { firstName, lastName, cpf: userCpf, birthDate } = user
        const { fullName: guarantorFullName } = guarantor
        const { upfrontValue, takenAmount, pmt, numberOfInstallments, monthlyInterest } = creditRequest
        const { partner, course, courses, userReceivesMoney } = product

        setData({
          guarantorName: capitalize(guarantorFullName) || 'Avalista',
          userFullName: capitalize(`${firstName} ${lastName}`),
          cpf: formatCpf(userCpf),
          birthDate: formatDate(birthDate),
          partner,
          course,
          courses,

          creditRequest,
          userReceivesMoney,

          upfrontValue: upfrontValue && formatValue(upfrontValue),
          numberOfInstallments,
          pmt: pmt && formatValue(pmt),
          takenAmount: takenAmount && formatValue(takenAmount),
          monthlyInterest: monthlyInterest || monthlyInterest === 0 ? formatPercent(monthlyInterest) : null
        })

        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        showAllToasts(error)
      }
    }
    getData()
  }, [])

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      await acceptedTermsAndConditions(ip)
      goToPage('/infos-basicas')
    } catch (error) {
      showToast(ERRORS.default)
    }
  }

  return [onSubmit, data]
}
