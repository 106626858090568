import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Section = styled.div`
  margin-bottom: ${theme.variables.spacer * 4}px;
`

export const Bold = styled.span`
  font-family: CenturyGothicBold, sans-serif;
`
