import React, { useContext } from 'react'
import { Title, SubTitle, Label, LoadingProvi } from '@provi/provi-components'
import { TemplateContext } from '~/components/template/context'

import { FormWrapper } from './form'
import { IntroModal } from './modal'
import { useIntroduction } from './hooks'
import { Wrapper, ContainerIntro, Content, Text, ContainerBackground } from './styles'

export const Introduction = () => {
  const { isMobile, isLoading, setIsLoading, setInfosSentry, setIp, goToPage } = useContext(TemplateContext)
  const [username, isShowModal, setIsShowModal, urlToken] = useIntroduction(setIsLoading, setInfosSentry, setIp, goToPage)

  return (
    <>
      {isLoading && <LoadingProvi isLoadingDark />}
      <Wrapper>
        <ContainerIntro>
          <Content>
            <IntroModal isShowModal={isShowModal} setIsShowModal={setIsShowModal} />

            <Title isDark text="Olá!" />
            <SubTitle
              isDark
              text={`Você está sendo convidado  ${username ? `por ${username}` : ''} para ser um avalista de crédito na Provi.`}
            />
            <Text isDark>
              A Provi é uma fintech que está revolucionando o mercado de empréstimos e financiamentos educacionais no Brasil.
            </Text>
            <Text isDark>
              Sendo assim, o crédito {!username || username === 'Null' ? `` : `de ${username}`} não é um empréstimo qualquer.
              Nossos financiamentos têm o intuito de impulsionar carreiras.
            </Text>

            <Label isDark text="Digite aqui o seu CPF para acessar" />
            <FormWrapper setIsShowModal={setIsShowModal} urlToken={urlToken} />
          </Content>
        </ContainerIntro>
        {!isMobile && <ContainerBackground />}
      </Wrapper>
    </>
  )
}
