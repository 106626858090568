import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: ${theme.variables.spacer * 3}px;
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
`
