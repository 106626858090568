import { useState, useEffect } from 'react'
import { theme } from '@provi/provi-components'
import { getCreditRequestInfo } from '~/services/api'
import { URL_EXTERNAL } from '~/constants'

export const useGuarantorListen = (setModal, setIsLoading, setCurrent, handleModal, goToPage) => {
  const [isShowModal, setIsShowModal] = useState(false)
  const [asset, setAsset] = useState()
  const [name, setName] = useState()

  useEffect(() => {
    setCurrent(86.66, 3)

    setIsLoading(false)

    const getName = async () => {
      try {
        const response = await getCreditRequestInfo('/avalistas')
        const { user } = response.data
        const { firstName } = user
        setName(firstName)
      } catch (error) {
        //
      }
    }
    getName()
  }, [setIsLoading])

  const handlePage = ref => {
    if (ref) {
      localStorage.setItem('asset-ref', ref)
    }

    setAsset(ref)
    setIsShowModal(true)
  }

  const handleModalButton = () => {
    setIsLoading(true)
    setModal(null)
    goToPage('/avalistas/enviar')
  }

  useEffect(() => {
    setModal({
      title: 'Por que precisamos do vídeo selfie ou da foto selfie?',
      text: [
        'Essa parte do processo é muito importante para a sua segurança digital.',
        'Através do vídeo selfie ou da foto selfie, nós conseguimos confirmar que é você mesmo quem está preenchendo essa solicitação, e assim evitamos qualquer tipo de fraude.'
      ],
      textButton: 'Entendi',
      buttonFunction: () => handleModal(false),
      textLink: {
        color: theme.colors.blue900,
        text: 'Abrir chat',
        onClick: () => window.open(URL_EXTERNAL, '_blank')
      }
    })
  }, [setModal])

  return [isShowModal, setIsShowModal, handlePage, handleModalButton, asset, name]
}
