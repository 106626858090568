import { useEffect, useState } from 'react'
import { getGuarantorInfo } from '~/services/api'
import { formatCpf, capitalize, showAllToasts } from '~/utils'

export const useBasicInfo = (setIsLoading, setCurrent, checkToken, setInfosSentry, guarantorInfo) => {
  const [data, setData] = useState(undefined)

  useEffect(() => {
    setIsLoading(true)
    checkToken()
    setCurrent(13.33, 2)

    const getData = async () => {
      try {
        let response
        if (guarantorInfo && guarantorInfo.CPF && guarantorInfo.fullName && guarantorInfo.birthDate) {
          // check if cache is in context
          response = { data: { ...guarantorInfo } }
        } else {
          response = await getGuarantorInfo('/basic-info')
        }

        const { CPF: cpf, fullName, birthDate, email, phone } = response.data
        setInfosSentry(props => ({ ...props, email, phone, name: capitalize(fullName) || 'sem nome' }))

        setData({
          cpf: formatCpf(cpf),
          fullName: capitalize(fullName),
          birthDate
        })
      } catch (error) {
        showAllToasts(error)
      }
      setIsLoading(false)
    }
    getData()
  }, [])

  return [data]
}

export const useForm = () => {
  const [isShowModal, setIsShowModal] = useState(false)

  const handleModal = bool => {
    setIsShowModal(bool)
  }

  return [isShowModal, setIsShowModal, handleModal]
}
