import React from 'react'

export const Lock = () => (
  <svg width="20" height="24" viewBox="0 0 20 24" fill="none">
    <path
      d="M15.8333 11H4.16667C3.24619 11 2.5 11.8954 2.5 13V20C2.5 21.1046 3.24619 22 4.16667 22H15.8333C16.7538 22 17.5 21.1046 17.5 20V13C17.5 11.8954 16.7538 11 15.8333 11Z"
      stroke="#A5A3A3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83337 11V7C5.83337 5.67392 6.27236 4.40215 7.05376 3.46447C7.83516 2.52678 8.89497 2 10 2C11.1051 2 12.1649 2.52678 12.9463 3.46447C13.7277 4.40215 14.1667 5.67392 14.1667 7V11"
      stroke="#A5A3A3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
