import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Text = styled.div`
  font-size: 20px;
  line-height: 30px;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`
