import React from 'react'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import InputAdornment from '@material-ui/core/InputAdornment'
import PropTypes from 'prop-types'
import { Button, Input, InputMasks, Label, Form } from '@provi/provi-components'

import { TextErrorInput } from '~/components/text-error-input'
import { showAllToasts } from '~/utils'

import { validateFullName, validateDate } from '~/utils/validators'
import { Lock } from '~/assets/svg/lock'
import { putGuarantorInfo } from '~/services/api'

import { Section } from './style'
import { Modal } from './Modal'
import { useForm } from './hooks'
import { logger } from '~/helpers/logger'

export const Formik = props => {
  const { handleChange, values, errors, touched, handleSubmit, handleBlur } = props

  const [isShowModal, setIsShowModal, handleModal] = useForm()

  return (
    <Form onSubmit={handleSubmit}>
      {isShowModal && <Modal setIsShowModal={setIsShowModal} />}
      <Section>
        <Label text="CPF" />
        <Input
          disabled
          id="cpf"
          onClick={() => handleModal(true)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Lock />
              </InputAdornment>
            ),
            'aria-label': 'cpf',
            cy: 'cpf'
          }}
          value={values.cpf}
        />
      </Section>

      <Section>
        <Label text="Nome completo" />
        <Input
          id="name"
          InputProps={{ 'aria-label': 'name', cy: 'input-name' }}
          placeholder="Digite seu nome completo"
          onBlur={handleBlur('name')}
          onChange={handleChange('name')}
          value={values.name === 'Null Null' ? '' : values.name}
        />
        {errors.name && touched.name && <TextErrorInput>{errors.name}</TextErrorInput>}
      </Section>

      <Section>
        <Label text="Data de nascimento" />
        <InputMasks
          type="birthDate"
          id="birthDate"
          InputProps={{ 'aria-label': 'birthDate', cy: 'input-birthDate' }}
          mask="99/99/9999"
          placeholder="Ex: 11/03/1992"
          onBlur={handleBlur('birthDate')}
          onChange={handleChange('birthDate')}
          value={values.birthDate}
        />
        {errors.birthDate && touched.birthDate && <TextErrorInput>{errors.birthDate}</TextErrorInput>}
      </Section>

      <Button
        marginHorizontal={0}
        marginVertical={24}
        cy="button"
        type="submit"
        disabled={!(values.name && values.birthDate && values.name !== 'Null Null') || errors.name || errors.birthDate}
        text="Avançar"
      />
    </Form>
  )
}
export const FormFormik = withFormik({
  mapPropsToValues: props => {
    const { data } = props

    return {
      cpf: data.cpf ? data.cpf : '',
      name: data.fullName ? data.fullName : '',
      birthDate: data.birthDate ? data.birthDate : ''
    }
  },
  validationSchema: () =>
    Yup.object().shape({
      name: Yup.string().test({
        name: 'name',
        message: 'Digite seu nome completo',
        test: name => validateFullName(name)
      }),
      birthDate: Yup.string()
        .test({
          name: 'birthDate',
          message: 'Você tem que ter entre 18 e 70 anos',
          test: birthDate => birthDate && birthDate.length === 10 && validateDate(birthDate)
        })
        .required('Digite uma data')
    }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { setIsLoading, setGuarantorInfo, goToPage } = props
    setSubmitting(false)

    try {
      setIsLoading(true)

      try {
        setGuarantorInfo(oldValues => ({ ...oldValues, fullName: values.name, birthDate: values.birthDate, CPF: values.cpf }))
      } catch (err) {
        logger({ error: err, ref: 'cache context (basic-info)' })
      }

      await putGuarantorInfo(values, 'basic-info')

      goToPage('/cep')
    } catch (error) {
      setIsLoading(false)
      showAllToasts(error)
    }
  }
})(Formik)

Formik.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.string).isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  touched: PropTypes.oneOfType([PropTypes.any]).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired
}
