import { useState, useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import firebase from '~/services/firebase'
import { getSelfie, postSelfie, getCreditRequestInfo, uploadImages } from '~/services/api'
import { showAllToasts } from '~/utils'
import { showToast } from '~/components/toast'
import { logger } from '~/helpers/logger'
import { getTypeArchive } from '~/services/get-type-archive'

export const useGuarantorSend = (setIsLoading, setCurrent, goToPage) => {
  const [error, setError] = useState(false)
  const [asset, setAsset] = useState()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isShowModal, setIsShowModal] = useState(false)
  const [numberOfFiles, setNumberOfFiles] = useState(0)
  const [isWebcamModal, setisWebcamModal] = useState(false)
  const [isButtonFileDisable, setIsButtonFileDisable] = useState({
    upload: false,
    take: false
  })
  const [urls, setUrls] = useState([])
  const [name, setName] = useState()

  const [preview, setPreview] = useState()
  const [filesNotUpload, setFilesNotUpload] = useState([])
  const [numberOfFilesInitial, setNumberOfFilesInitial] = useState(0)

  const [applicationType, setApplicationType] = useState([])
  const [isWebcam, setWebcam] = useState(false)

  const getData = async () => {
    const assetStorage = localStorage.getItem('asset-ref')

    try {
      setFilesNotUpload([])

      const response = await getSelfie()
      const { data } = response.data

      const haveAsset = data.filter(d => assetStorage === d.type)
      setNumberOfFiles(haveAsset.length)
      setNumberOfFilesInitial(haveAsset.length)

      if (data.length > 0 && haveAsset.length > 0) {
        let responseType
        if (!data.typeExtension) {
          responseType = await getTypeArchive({ urls: [data[0].data] })
        } else {
          responseType = data[0].typeExtension
        }

        setApplicationType(responseType)
        setPreview(haveAsset[0].data)

        setIsButtonDisabled(false)
        setIsButtonFileDisable({
          upload: false,
          take: true
        })
      } else {
        setPreview('')
      }
    } catch (err) {
      showAllToasts(err)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)

    const assetStorage = localStorage.getItem('asset-ref')

    if (!assetStorage) {
      goToPage('/avalistas')
    } else {
      setAsset(assetStorage)
    }

    setCurrent(93.33, 3)

    getData()

    const getName = async () => {
      try {
        const response = await getCreditRequestInfo('/avalistas/enviar')
        const { user } = response.data
        const { firstName } = user
        setName(firstName)
      } catch (err) {
        //
      }
    }
    getName()
  }, [])

  useEffect(() => {
    if (filesNotUpload.length > 0) {
      setIsButtonDisabled(false)
      setIsButtonFileDisable({
        upload: false,
        take: true
      })
    }
  }, [filesNotUpload])

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      if (filesNotUpload.length > 0 || asset === 'video') {
        setIsLoading(true)

        const urlsImgs = urls.length ? urls : await uploadImages('selfie', filesNotUpload, asset)

        if (urlsImgs.length !== 0) {
          await postSelfie(urlsImgs)

          goToPage('/sucesso')
        } else {
          getData()

          Sentry.captureException(new Error(`onSubmit(selfie - ${asset}): files is empty`))
          throw new Error('Ocorreu um erro ao enviar o arquivo. Tente novamente ou entre em contato com a Provi.')
        }
      } else {
        // send POST request even if user didn't change images because in ProviPayV3 we need a POST request to update this guarantor status to analysis
        const currentImages = [{ data: preview, type: asset, typeExtension: applicationType[0] }]

        setIsLoading(false)
        await postSelfie(currentImages)
        goToPage('/sucesso')
      }
    } catch (err) {
      showToast('Ocorreu um erro ao enviar o arquivo. Tente novamente ou entre em contato com a Provi.')
    } finally {
      setIsLoading(false)
    }
  }

  const onDrop = dropFile => {
    setIsButtonDisabled(true)
    setError(false)

    if (numberOfFiles >= 10) {
      showToast('Limite maximo de uploads atingido')
      setIsButtonDisabled(false)
    } else {
      const reader = new FileReader()

      reader.onerror = err => {
        logger({ error: err, screen: '/avalistas/enviar (fn onDrop)' })
      }

      reader.onload = async () => {
        const now = new Date().getTime()
        let storagePath

        try {
          const response = await getCreditRequestInfo('/avalistas/enviar')
          const { creditRequest } = response.data
          setFilesNotUpload([...filesNotUpload, ...dropFile])

          if (asset) {
            storagePath = `/${creditRequest.id ? creditRequest.id : '00000'}/${asset}/${now}`
          } else {
            storagePath = `/${creditRequest.id ? creditRequest.id : '00000'}/${now}`
          }
        } catch (err) {
          //
        }

        const uploadRef = firebase.storage().ref(storagePath)
        const uploadTask = uploadRef.put(dropFile[0])

        uploadTask.on(
          'state_changed',
          () => {},
          err => {
            logger({ error: err, ref: 'Firebase upload error' })
          },
          async () => {
            const url = await uploadRef.getDownloadURL()
            setUrls([...urls, { data: url, type: asset }])
            setIsButtonDisabled(false)

            setNumberOfFiles(numberOfFiles + 1)
          }
        )
      }

      try {
        reader.readAsDataURL(dropFile[0])
      } catch (err) {
        if (numberOfFiles > 0) {
          setIsButtonDisabled(false)
        }
        showToast('Ocorreu um erro tente novamente')
        logger({ error: err, screen: '/avalistas/enviar (fn onDrop)' })
      }
    }
  }

  const handleModalButton = () => {
    setIsShowModal(false)
  }

  return [
    onDrop,
    handleSubmit,
    error,
    setIsButtonDisabled,
    isButtonDisabled,
    isShowModal,
    setIsShowModal,
    handleModalButton,
    numberOfFiles,
    isWebcamModal,
    setisWebcamModal,
    isButtonFileDisable,
    setIsButtonFileDisable,
    urls,
    setUrls,
    name,
    //
    //
    preview,
    setPreview,
    filesNotUpload,
    setFilesNotUpload,
    setNumberOfFiles,
    numberOfFilesInitial,
    applicationType,
    isWebcam,
    setWebcam
  ]
}
