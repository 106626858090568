import React, { useContext } from 'react'
import { Title, SubHeaderDesktop, Button, Container, UploadBox } from '@provi/provi-components'
import { TemplateContext } from '~/components/template/context'
import { TextErrorInput } from '~/components/text-error-input'
import { UploadFile } from '~/components/upload-file'
import { Modal } from './modal'
import SelfieHand from '~/assets/svg/selfie-hand.svg'
import VideoHand from '~/assets/svg/video-hand.svg'
import { ContainerErrorInput, Text, WrapperFiles, SubTitle, WrapperTexts } from './style'
import { useGuarantorSend } from './hooks'

const OPTIONS_DROPZONE = 'image/jpeg, image/png, application/pdf'
const OPTIONS_DROPZONE_VIDEO = 'video/*'

export const GuarantorSend = () => {
  const { handleModal, isMobile, setCurrent, setIsLoading, goToPage } = useContext(TemplateContext)
  const [
    onDrop,
    handleSubmit,
    error,
    setIsButtonDisabled,
    isButtonDisabled,
    isShowModal,
    setIsShowModal,
    handleModalButton,
    numberOfFiles,
    isWebcamModal,
    setisWebcamModal,
    isButtonFileDisable,
    setIsButtonFileDisable,
    urls,
    setUrls,
    name,
    //
    //
    preview,
    setPreview,
    filesNotUpload,
    setFilesNotUpload,
    setNumberOfFiles,
    numberOfFilesInitial,
    applicationType,
    isWebcam,
    setWebcam
  ] = useGuarantorSend(setIsLoading, setCurrent, goToPage)

  const asset = localStorage.getItem('asset-ref')
  const data =
    asset === 'video'
      ? {
          title: 'o seu vídeo',
          subTitle: 'Lembre-se que o vídeo precisa ter qualidade de imagem e de som',
          img: 'pink',
          modalTitle: 'Passo a passo do vídeo:',
          text: 'Grave um vídeo seu dizendo: ',
          bold: 'Eu aceito ser avalista do Mario na Provi',
          textButton: 'Gravar vídeo'
        }
      : {
          title: 'a sua foto',
          subTitle: '',
          img: 'black',
          modalTitle: 'Passo a passo da selfie:',
          text: 'Tire uma foto de você mesmo, segurando um papel escrito em letras de de forma.',
          bold: 'SouAvalistaNaProvi do Mario',
          textButton: 'Tirar foto'
        }

  return (
    <>
      {isShowModal && <Modal asset={asset} handleModalButton={handleModalButton} setIsShowModal={setIsShowModal} name={name} />}
      <Container>
        <WrapperTexts>
          {isMobile ? (
            <Title marginVertical={0} text={`Envie ${data.title} selfie`} />
          ) : (
            <SubHeaderDesktop title={`Envie ${data.title} selfie`} handleModal={handleModal} />
          )}

          {data.subTitle && <SubTitle>{data.subTitle}</SubTitle>}
        </WrapperTexts>

        {isMobile && (
          <>
            <UploadBox
              preview={preview}
              setPreview={setPreview}
              setFilesNotUpload={setFilesNotUpload}
              filesNotUpload={filesNotUpload}
              setNumberOfFiles={setNumberOfFiles}
              numberOfFiles={numberOfFiles}
              image={SelfieHand}
              numberOfFilesInitial={numberOfFilesInitial}
              options={asset === 'video' ? OPTIONS_DROPZONE_VIDEO : OPTIONS_DROPZONE}
              type={asset}
            />
            {error && (
              <ContainerErrorInput>
                <TextErrorInput>Por favor, envie uma foto ou pdf</TextErrorInput>
              </ContainerErrorInput>
            )}
          </>
        )}

        {!isMobile && (
          <WrapperFiles>
            <UploadBox
              isWebcam={isWebcam}
              applicationType={applicationType}
              width={216}
              preview={preview}
              setPreview={setPreview}
              setFilesNotUpload={setFilesNotUpload}
              filesNotUpload={filesNotUpload}
              setNumberOfFiles={setNumberOfFiles}
              numberOfFiles={numberOfFiles}
              image={asset === 'video' ? VideoHand : SelfieHand}
              numberOfFilesInitial={numberOfFilesInitial}
              options={asset === 'video' ? OPTIONS_DROPZONE_VIDEO : OPTIONS_DROPZONE}
              type={asset}
            />
            <Text>ou</Text>
            <UploadFile
              asset={asset}
              setFilesNotUpload={setFilesNotUpload}
              filesNotUpload={filesNotUpload}
              disabled={isButtonFileDisable.take}
              setIsButtonDisabled={setIsButtonDisabled}
              setIsButtonFileDisable={setIsButtonFileDisable}
              status={asset === 'video' ? 'webcam-video' : 'webcam'}
              isWebcamModal={isWebcamModal}
              onDrop={onDrop}
              urls={{ urls, setUrls }}
              setisWebcamModal={setisWebcamModal}
              setWebcam={setWebcam}
            />
          </WrapperFiles>
        )}

        <Button
          id="selfie-review-step-by-step-button"
          marginHorizontal={0}
          type="button"
          disabled={isButtonDisabled}
          onClick={() => handleSubmit()}
          text="Avançar"
          textLink={{ text: 'Rever passo a passo', onClick: () => setIsShowModal(true) }}
        />
      </Container>
    </>
  )
}
