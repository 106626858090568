import { useEffect, useState } from 'react'
import { showAllToasts } from '~/utils'
import { getGuarantorInfo } from '~/services/api'

export const useEmail = (setIsLoading, setCurrent, checkToken, guarantorInfo) => {
  const [data, setData] = useState(undefined)

  useEffect(() => {
    setIsLoading(true)
    checkToken()
    setCurrent(26.66, 2)

    const getData = async () => {
      try {
        let response
        if (guarantorInfo && guarantorInfo.email && guarantorInfo.firstName) {
          response = { data: { ...guarantorInfo } }
        } else {
          response = await getGuarantorInfo('/email')
        }

        const { email, firstName } = response.data

        setData({
          email,
          firstName
        })

        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        showAllToasts(error)
      }
    }
    getData()
  }, [])

  return [data]
}
