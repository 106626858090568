import * as Sentry from '@sentry/browser'

export const logger = props => {
  if (props.error) console.log('ERROR ->>>>>: ', props.error)
  if (props.info) console.log('INFO EXTRA ->>>>>: ', props.info.componentStack)

  if (props && props.error) {
    Sentry.withScope(scope => {
      props.info &&
        Object.keys(props.info).forEach(key => {
          scope.setExtra(key, props.info[key])
        })

      Sentry.captureException(props.error)
    })
  }
}
