import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Text = styled.div`
  font-family: 'CenturyGothic', sans-serif;
  color: ${theme.colors.blue900};
  font-size: 20px;
  line-height: 30px;

  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 25px;
  }
`

export const Bold = styled.span`
  color: ${theme.colors.blue900};
  font-family: 'CenturyGothicBold', sans-serif;
`

export const WrapperTitle = styled.div`
  margin-bottom: 20px;
`
