import styled, { css, keyframes } from 'styled-components'
import { theme } from '~/theme'

export const Container = styled.div`
  width: 216px;
  height: 216px;
  border: 1px dashed ${({ disabled }) => (disabled ? '#999' : '#b6dfe7')};

  ${({ disabled }) =>
    disabled &&
    css`
      > div {
        color: #999 !important;
      }
    `};

  box-sizing: border-box;
  border-radius: 20px;
  margin: 30px 0;
  /* margin-right: 20px; */
  position: relative;
  padding: 15px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  user-select: none;

  display: flex;
  flex-direction: column;
  align-items: center;

  &:focus {
    outline: 0;
  }

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const animationHeight = keyframes`
  0% {
    height: 0;
  }
  100% {
    height: 101%;
  }
`

export const Animation = styled.div`
  position: absolute;
  width: 100%;
  height: 101%;
  background-color: ${theme.colors.blue900};
  bottom: 0;
  margin-top: 10px;
  border-radius: 20px;
  animation-name: ${animationHeight};
  animation-duration: 3s;
  animation-timing-function: ease;

  ${theme.breakpoints.down('md')} {
    width: 101.3%;
  }
`

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin-top: 0;

  width: 130px;
  ${({ margin }) =>
    margin &&
    css`
      margin-top: 14px;
    `}

  ${({ withoutPreview }) =>
    withoutPreview &&
    css`
      margin-top: 22px;
      width: 85px;
    `}
`

export const Text = styled.div`
  font-size: 16px;
  line-height: 30px;
  text-decoration: underline;
  color: ${theme.colors.blue900};
  width: 142px;
  text-align: center;
  font-family: 'CenturyGothicBold';
  margin: 11px 15px;

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 24px;
  }
`

export const IconContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`

export const ContainerComplete = styled.div`
  width: 216px;
  height: 216px;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 30px 0;
  position: relative;
  padding: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${theme.colors.blue900};
  &:focus {
    outline: 0;
  }

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const ContainerUploadInfo = styled.div`
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 74px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  &:focus {
    outline: 0;
  }
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const TitleUploadInfo = styled.p`
  font-family: CenturyGothicBold, sans-serif;
  margin: 0;
  color: ${theme.colors.green100};
  font-size: 16px;
`

export const LinkText = styled.div`
  font-family: CenturyGothicBold, sans-serif;
  margin-top: 8px;
  text-decoration: underline;
  color: white;
  font-size: 12px;
  margin-right: 8px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;

  div {
    margin: 0 auto;
    border-radius: 8px;

    video {
      background-color: white;
      border-radius: 8px;
      width: 100%;
      height: 100%;
    }
  }
`

export const PreviewWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`

export const Preview = styled.img`
  width: 100%;
  height: 100%;
  display: flex;
`

export const WrapperButton = styled.div`
  margin: 20px auto !important;
`

export const WrapperWebcam = styled.div`
  width: 100%;
  height: 100%;
`
