import React from 'react'
import PropTypes from 'prop-types'

import { WebcamWrapper } from './status/webcam'
import { WebcamVideo } from './status/webcam-video'

import VideoHand from '~/assets/svg/video-hand.svg'
import VideoHandDisabled from '~/assets/svg/video-hand-disabled.svg'

import SelfieHand from '~/assets/svg/selfie-hand.svg'
import SelfieHandDisabled from '~/assets/svg/selfie-hand-disabled.svg'

export const UploadFile = props => {
  const {
    status,
    onDrop,
    asset,
    isWebcamModal,
    setisWebcamModal,
    disabled,
    setIsButtonFileDisable,
    setIsButtonDisabled,
    urls,
    setWebcam,
    filesNotUpload,
    setFilesNotUpload
  } = props

  let setImg
  if (asset === 'video') {
    if (disabled) {
      setImg = VideoHandDisabled
    } else {
      setImg = VideoHand
    }
  } else if (disabled) {
    setImg = SelfieHandDisabled
  } else {
    setImg = SelfieHand
  }

  if (status === 'webcam') {
    return (
      <WebcamWrapper
        setWebcam={setWebcam}
        asset={asset}
        setIsButtonDisabled={setIsButtonDisabled}
        setIsButtonFileDisable={setIsButtonFileDisable}
        disabled={disabled}
        onDrop={onDrop}
        img={setImg}
        isWebcamModal={isWebcamModal}
        setisWebcamModal={setisWebcamModal}
        urls={urls}
        setFilesNotUpload={setFilesNotUpload}
        filesNotUpload={filesNotUpload}
      />
    )
  }

  if (status === 'webcam-video') {
    return (
      <WebcamVideo
        setWebcam={setWebcam}
        disabled={disabled}
        setIsButtonDisabled={setIsButtonDisabled}
        setIsButtonFileDisable={setIsButtonFileDisable}
        isWebcamModal={isWebcamModal}
        asset={asset}
        img={setImg}
        setisWebcamModal={setisWebcamModal}
        urls={urls}
        setFilesNotUpload={setFilesNotUpload}
        filesNotUpload={filesNotUpload}
      />
    )
  }
}

UploadFile.defaultProps = {
  numberOfFiles: 0,
  file: {},
  status: 'pending',
  onDrop: () => {},
  error: {},
  asset: '',
  isWebcamModal: false,
  setisWebcamModal: () => {},
  disabled: true,
  setIsButtonFileDisable: () => {},
  setIsButtonDisabled: () => {}
}

UploadFile.propTypes = {
  numberOfFiles: PropTypes.number,
  file: PropTypes.objectOf(PropTypes.any),
  status: PropTypes.string,
  onDrop: PropTypes.func,
  error: PropTypes.objectOf(PropTypes.any),
  asset: PropTypes.string,
  isWebcamModal: PropTypes.bool,
  setisWebcamModal: PropTypes.func,
  disabled: PropTypes.bool,
  setIsButtonFileDisable: PropTypes.func,
  setIsButtonDisabled: PropTypes.func
}
