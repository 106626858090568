import { useCallback, useRef, useState, useEffect } from 'react'
import firebase from '~/services/firebase'
import { logger } from '~/helpers/logger'
import { getCreditRequestInfo } from '~/services/api'

export const useUpload = (
  setisWebcamModal,
  setIsButtonFileDisable,
  setIsButtonDisabled,
  urls,
  setIsLoading,
  setWebcam,
  setFilesNotUpload,
  filesNotUpload
) => {
  const [image, setImage] = useState()
  const [status, setStatus] = useState('processing')
  const [isButtonModalDisabled, setIsButtonModalDisabled] = useState(false)

  useEffect(() => {
    if (status === 'complete') {
      setIsButtonFileDisable({ upload: true, take: false })
      setIsButtonDisabled(false)
    }
  }, [setIsButtonDisabled, setIsButtonFileDisable, status])

  const videoConstraints = {
    width: 640,
    height: 360,
    facingMode: 'user'
  }

  const webcamRef = useRef(null)

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()
    setImage(imageSrc)
  }, [webcamRef])

  const b64toBlob = dataURI => {
    const byteString = atob(dataURI.split(',')[1])
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], { type: 'image/jpeg' })
  }

  const onDrop = dropFile => {
    setIsLoading(true)
    const asset = localStorage.getItem('asset-ref')

    // setError(false)
    const reader = new FileReader()

    reader.onerror = err => {
      logger({ error: err, screen: 'component upload-file (fn onDrop)' })
    }

    reader.onload = async () => {
      // setStatus('processing')

      const now = new Date().getTime()
      let storagePath

      try {
        const response = await getCreditRequestInfo('(component upload-file fn useUpload)')
        const { creditRequest } = response.data
        setFilesNotUpload([...filesNotUpload, ...dropFile])

        if (asset) {
          storagePath = `/${creditRequest.id ? creditRequest.id : '00000'}/${asset}/${now}`
        } else {
          storagePath = `/${creditRequest.id ? creditRequest.id : '00000'}/${now}`
        }
      } catch (error) {
        //
      }

      firebase.auth().signInAnonymously()

      const uploadRef = firebase.storage().ref(storagePath)
      const uploadTask = uploadRef.put(dropFile[0])

      uploadTask.on(
        'state_changed',
        () => {},
        err => {
          logger({ error: err, screen: 'component upload-file (fn onDrop)' })
          setIsLoading(false)
        },
        async () => {
          const url = await uploadRef.getDownloadURL()
          urls.setUrls([...urls.urls, { data: url, type: asset }])

          setStatus('complete')

          setisWebcamModal(false)
          setIsButtonModalDisabled(false)
          setIsLoading(false)
          setWebcam(true)
        }
      )
    }

    try {
      reader.readAsDataURL(dropFile[0])
    } catch (err) {
      logger({ error: err, screen: 'component upload-file (fn onDrop)' })
      setIsLoading(false)
    }
  }

  const handleImage = () => {
    if (!image) {
      capture()
    } else {
      const blob = b64toBlob(image)
      setIsButtonModalDisabled(true)
      onDrop([blob])
    }
  }

  const completeClick = () => {
    if (image) {
      setIsButtonModalDisabled(true)
    }

    setisWebcamModal(true)
  }

  const takeNewPhoto = () => {
    setIsButtonModalDisabled(false)
    setImage(null)
  }

  return [handleImage, image, webcamRef, videoConstraints, status, isButtonModalDisabled, completeClick, takeNewPhoto]
}

export const useVideoUpload = (
  setisWebcamModal,
  setIsButtonFileDisable,
  setIsButtonDisabled,
  urls,
  setIsLoading,
  setWebcam,
  setFilesNotUpload,
  filesNotUpload
) => {
  const [video, setVideo] = useState()
  const [error, setError] = useState(false)
  const [status, setStatus] = useState('processing')
  const [isButtonModalDisabled, setIsButtonModalDisabled] = useState(true)

  useEffect(() => {
    if (status === 'complete') {
      setIsButtonFileDisable({ upload: true, take: false })
      setIsButtonDisabled(false)
    }
  }, [setIsButtonDisabled, setIsButtonFileDisable, status])

  const onDrop = file => {
    setIsLoading(true)
    const asset = localStorage.getItem('asset-ref')
    setIsButtonModalDisabled(true)

    setError(false)
    const reader = new FileReader()

    reader.onerror = readerError => {
      logger({ error: readerError, screen: 'component upload-file (fn onDrop)' })
    }

    reader.onload = async () => {
      setStatus('processing')

      const now = new Date().getTime()
      let storagePath

      try {
        const response = await getCreditRequestInfo('(component upload-file fn useVideoUpload)')
        const { creditRequest } = response.data
        setFilesNotUpload([...filesNotUpload, ...file])

        if (asset) {
          storagePath = `/${creditRequest.id ? creditRequest.id : '00000'}/${asset}/${now}`
        } else {
          storagePath = `/${creditRequest.id ? creditRequest.id : '00000'}/${now}`
        }
      } catch (err) {
        //
      }

      firebase.auth().signInAnonymously()

      const uploadRef = firebase.storage().ref(storagePath)
      const uploadTask = uploadRef.put(file[0])

      uploadTask.on(
        'state_changed',
        () => {},
        err => {
          logger({ error: err, screen: 'component upload-file (fn onDrop)' })
          setIsLoading(false)
        },
        async () => {
          const url = await uploadRef.getDownloadURL()
          setStatus('complete')
          urls.setUrls([...urls.urls, { data: url, type: asset }])

          setisWebcamModal(false)
          setIsButtonModalDisabled(false)
          setIsButtonDisabled(false)
          setIsLoading(false)
          setWebcam(true)
        }
      )
    }

    try {
      reader.readAsDataURL(file[0])
    } catch (err) {
      logger({ error: err, screen: 'component upload-file (fn onDrop)' })
    }
  }

  const handleVideo = () => {
    if (video) {
      setIsButtonModalDisabled(false)
      onDrop([video])
    }
  }

  const completeClick = () => {
    if (video) {
      setIsButtonModalDisabled(true)
    }

    setisWebcamModal(true)
  }

  return [handleVideo, setVideo, status, isButtonModalDisabled, setIsButtonModalDisabled, completeClick, error]
}
