import styled from 'styled-components'
import background_right_desktop from '~/assets/svg/background-right-desktop.svg'
import { theme } from '~/theme'

export const View = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  overflow-y: scroll;
`

export const TitleContainer = styled.div`
  display: flex;
  width: 60%;
  ${theme.breakpoints.down('lg')} {
    width: 90%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const TextModal = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-top: 30px;
  color: ${theme.colors.blue900};
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
`
export const Left = styled.div`
  width: 65%;
  height: 100vh;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  z-index: 6;

  ${theme.breakpoints.down('md')} {
    min-height: unset;
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0;
    align-items: center;
  }
`

export const Right = styled.div`
  width: 35%;
  height: 100vh;
  position: fixed;
  right: 0;
  background: url(${background_right_desktop});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100%;
  transform: translate(1px, 1px);
`
