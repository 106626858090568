import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal as ModalComponent } from '@provi/provi-components'
import { Text } from './style'

const URL_EXTERNAL = 'https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20?'

export const Modal = ({ setIsShowModal }) => {
  const data = {
    title: 'Por que meu CPF está travado?',
    text:
      'Fazemos isso para proteger você de futuras fraudes. Esse foi o CPF que o solicitante do crédito nos passou no começo do processo de crédito. Se esse não for seu CPF entre em contato conosco.'
  }

  return (
    <ModalComponent
      title={data.title}
      emoji=":/"
      onDismiss={() => setIsShowModal(false)}
      footer={
        <Button
          type="button"
          text="Entendi"
          textLink={{ text: 'Abrir chat', onClick: () => window.open(URL_EXTERNAL, '_blank') }}
          onClick={() => setIsShowModal(false)}
        />
      }
    >
      <Text>{data.text}</Text>
    </ModalComponent>
  )
}

Modal.propTypes = {
  setIsShowModal: PropTypes.func.isRequired
}
