import React, { useContext } from 'react'
import { TextLink, Title, SubTitle, SubHeaderDesktop, Container } from '@provi/provi-components'
import { TemplateContext } from '~/components/template/context'
import { CodeView, CodeNumber, ContainerComponent } from './style'
import { useCodeValidator } from './hooks'

export const ConfirmationPhone = () => {
  const { handleModal, isMobile, setCurrent, setModal, setIsLoading, goToPage } = useContext(TemplateContext)
  const [code, setCodeNumber, addInput, setNextFocus, onKeyDown, handleCellphone, data] = useCodeValidator(
    setModal,
    setIsLoading,
    setCurrent,
    handleModal,
    goToPage
  )

  return (
    <>
      {data && (
        <Container>
          {isMobile ? (
            <Title text="Verificação de celular" />
          ) : (
            <SubHeaderDesktop title="Verificação de celular" handleModal={handleModal} isShowButtonHelpModal />
          )}
          <SubTitle text={`Insira abaixo o código que enviamos via SMS para o número ${data}`} />
          <ContainerComponent>
            <CodeView>
              {code.map((value, position) => (
                <CodeNumber
                  autoFocus={position === 0}
                  id={`id-${position}`}
                  aria-label={`id-${position}`}
                  cy={`cy-${position}`}
                  ref={addInput}
                  key={position}
                  onKeyDown={e => onKeyDown(e, position)}
                  onChange={({ target }) => {
                    setCodeNumber(target.value, position)
                    setNextFocus(target.value, position)
                  }}
                />
              ))}
            </CodeView>

            <TextLink id="resend-mobile-code-button" text="Reenviar código" onClick={() => handleCellphone()} />
          </ContainerComponent>
        </Container>
      )}
    </>
  )
}
