import React, { useContext } from 'react'
import { Title, Container, SubHeaderDesktop, TextLink, theme } from '@provi/provi-components'
import { TemplateContext } from '~/components/template/context'
import { Text, Bold, Content, Warning, WarningAnimation, BoldWarning, Section } from './styles'
import { useEmail } from './hooks'

export const EmailValidation = () => {
  const { setCurrent, setModal, setIsLoading, isMobile, handleModal, checkToken, goToPage } = useContext(TemplateContext)
  const [handleEmail, data, animation] = useEmail(setModal, setIsLoading, setCurrent, checkToken, handleModal, goToPage)

  return (
    <>
      {data && (
        <Container>
          <Content>
            {isMobile ? (
              <Title text="Verificação de email" />
            ) : (
              <SubHeaderDesktop title="Verificação de email" handleModal={handleModal} isShowButtonHelpModal />
            )}

            <Text>Enviamos um email de confirmação para:</Text>
            <Bold>{data}</Bold>
            <Text>Você precisa validar esse email para prosseguir</Text>

            {animation && (
              <WarningAnimation>
                <Warning>
                  <Section>
                    <BoldWarning>Acesse seu e-mail para prosseguir</BoldWarning>
                  </Section>
                  <Section>
                    <TextLink color={theme.colors.grey400} text="Reenviar email" onClick={() => handleEmail()} />
                  </Section>
                </Warning>
              </WarningAnimation>
            )}

            <TextLink id="resend-email-button" text="Reenviar email" onClick={() => handleEmail()} />
          </Content>
        </Container>
      )}
    </>
  )
}
