import { useState, useEffect } from 'react'
import * as Sentry from '@sentry/browser'

import { getPersonalDocument, postPersonalDocument, uploadImages } from '~/services/api'
import { showToast } from '~/components/toast'
import { ERRORS } from '~/constants'
import { getTypeArchive } from '~/services/get-type-archive'

export const usePersonalDocument = (setModal, setIsLoading, setCurrent, goToPage) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [preview, setPreview] = useState(undefined)
  const [filesNotUpload, setFilesNotUpload] = useState([])
  const [numberOfFiles, setNumberOfFiles] = useState(0)
  const [numberOfFilesInitial, setNumberOfFilesInitial] = useState(0)
  const [applicationType, setApplicationType] = useState([])
  const [dropzoneState, setDropzoneState] = useState('pending')

  const getData = async () => {
    try {
      setFilesNotUpload([])

      const response = await getPersonalDocument('RG', 'FRONT')
      const { data } = response.data

      setNumberOfFiles(data.length)
      setNumberOfFilesInitial(data.length)

      if (data.length > 0) {
        let responseType
        if (!data.typeExtension) {
          responseType = await getTypeArchive({ urls: [data[0].data] })
        } else {
          responseType = data.typeExtension
        }

        setApplicationType(responseType)
        setPreview(data[0].data)
        setIsButtonDisabled(false)
      } else {
        setPreview('')
        setDropzoneState('pending')
      }
    } catch (err) {
      showToast(ERRORS.default)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    setCurrent(66.66, 3)

    getData()
  }, [])

  useEffect(() => {
    if (filesNotUpload.length > 0) {
      setIsButtonDisabled(false)
    }
  }, [filesNotUpload])

  const handleSubmit = async () => {
    setIsLoading(true)

    if (filesNotUpload.length > 0) {
      setIsLoading(true)
      try {
        const urls = await uploadImages('rg/front', filesNotUpload)
        if (urls.length) {
          await postPersonalDocument({ downloadURL: urls, documentType: 'RG', photoSide: 'FRONT' })

          goToPage('/documento-pessoal/rg/costas')
        } else {
          getData()

          Sentry.captureException(new Error(`onSubmit(rg/front): files is empty`))
          throw new Error('Ocorreu um erro ao enviar o arquivo. Tente novamente ou entre em contato com a Provi.')
        }
      } catch (e) {
        showToast('Ocorreu um erro ao enviar o arquivo. Tente novamente ou entre em contato com a Provi.')
      } finally {
        setIsLoading(false)
      }
    } else {
      setIsLoading(false)
      goToPage('/documento-pessoal/rg/costas')
    }
  }

  return [
    handleSubmit,
    isButtonDisabled,
    preview,
    setPreview,
    setFilesNotUpload,
    filesNotUpload,
    setNumberOfFiles,
    numberOfFiles,
    numberOfFilesInitial,
    applicationType,
    dropzoneState,
    setDropzoneState
  ]
}
