import { useEffect, useState } from 'react'

export const useDocuments = (setIsLoading, setCurrent, goToPage) => {
  const [isShowModal, setIsShowModal] = useState(false)
  useEffect(() => {
    setCurrent(53.33, 3)
    setIsLoading(false)
  }, [setIsLoading])

  const handlePage = () => {
    setIsLoading(true)
    goToPage('/documento-pessoal')
  }

  return [handlePage, setIsShowModal, isShowModal]
}
