const { REACT_APP_FRONT_END_TOKEN_END, REACT_APP_API_ENV } = process.env

const URLS = {
  production: {
    guarantor: 'https://ms-guarantor.provi.com.br',
    workerUpload: 'https://workers.provi.com.br/api/file-extension',
    creditSdk: 'https://credito.principia.net'
  },
  staging: {
    guarantor: 'https://ms-guarantor-staging.provi.com.br',
    workerUpload: 'https://workersstaging.provi.com.br/api/file-extension',
    creditSdk: 'https://credito-staging.principia.net'
  },
  development: {
    guarantor: 'https://ms-guarantor-dev.provi.com.br',
    workerUpload: 'https://workersdev.provi.com.br/api/file-extension',
    creditSdk: 'https://credito-staging.principia.net'
  }
}

export const URL_EXTERNAL = 'https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20?'

export const setRootUrl = environment => {
  switch (environment) {
    case 'production':
      return URLS.production
    case 'staging':
      return URLS.staging
    case 'development':
      return URLS.development
    default:
      return URLS.development
  }
}
const _ROOT_URL = setRootUrl(REACT_APP_API_ENV)

console.log('[ROOT_URL]: ', _ROOT_URL)

export const CEP_URL = 'https://api.postmon.com.br/v1/cep'
export const ROOT_URL = _ROOT_URL
export const FRONT_END_TOKEN = REACT_APP_FRONT_END_TOKEN_END
export const termsAndConditionsLink = 'https://provi.com.br/terms-and-conditions'
export const privacyPolicyLink = 'https://provi.com.br/privacy-policy'
export const scrText = 'A consulta e compartilhamento de informações do SCR do banco central.'

export const BASIC_INFO_STATUS = {
  incomplete: 'incomplete',
  analysis: 'analysis',
  approved: 'approved',
  review: 'review',
  denied: 'denied'
}
export const DOCUMENT_STATUS = {
  incomplete: 'incomplete',
  review: 'review',
  analysis: 'analysis',
  approved: 'approved',
  denied: 'denied'
}
export const GUARANTOR_STATUS = {
  unneeded: 'unneeded',
  needed: 'needed',
  incomplete: 'incomplete',
  analysis: 'analysis',
  review: 'review',
  approved: 'approved',
  denied: 'denied'
}
export const STATUS = {
  incomplete: 'incomplete',
  analysis: 'analysis',
  waiting_contract: 'waiting_contract',
  approved: 'approved',
  waiting_signature: 'waiting_signature',
  signed: 'signed',
  transferred: 'transferred',
  settled: 'settled',
  denied: 'denied',
  canceled: 'canceled'
}

export const ERRORS = {
  default: 'Ops, ocorreu algum erro 😔',
  introduction: {
    cpfInvalid: 'CPF inválido',
    token: 'Ocorreu um erro na url por favor tente novamente',
    auth: 'Erro ao autenticar'
  },
  auth: {
    invalidToken: 'Token inválido',
    expiredToken: 'Seu token expirou, por favor entre em um link válido',
    notAuthorized: 'Ação não autorizada',
    invalidCpf: 'CPF Inválido',
    alreadyInAnalysis: 'Você não pode acessar essa plataforma porque suas informações já estão em análise.',
    alreadyApproved: 'Você não pode acessar essa plataforma porque suas informações já foram aprovadas.'
  }
}

export const STATES = [
  { id: 1, label: 'AC' },
  { id: 2, label: 'AL' },
  { id: 3, label: 'AP' },
  { id: 4, label: 'AM' },
  { id: 5, label: 'BA' },
  { id: 6, label: 'CE' },
  { id: 7, label: 'DF' },
  { id: 8, label: 'ES' },
  { id: 9, label: 'GO' },
  { id: 10, label: 'MA' },
  { id: 11, label: 'MT' },
  { id: 12, label: 'MS' },
  { id: 13, label: 'MG' },
  { id: 14, label: 'PA' },
  { id: 15, label: 'PB' },
  { id: 16, label: 'PR' },
  { id: 17, label: 'PE' },
  { id: 18, label: 'PI' },
  { id: 19, label: 'RJ' },
  { id: 20, label: 'RN' },
  { id: 21, label: 'RS' },
  { id: 22, label: 'RO' },
  { id: 23, label: 'RR' },
  { id: 24, label: 'SC' },
  { id: 25, label: 'SP' },
  { id: 26, label: 'SE' },
  { id: 27, label: 'TO' }
]
