import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@provi/provi-components'
import { ModalText } from './styles'

export const IntroModal = ({ isShowModal, setIsShowModal }) => {
  return (
    <>
      {isShowModal && (
        <Modal isWithoutHeight title="O que é um avalista?" onDismiss={() => setIsShowModal(false)}>
          <ModalText>
            Avalista é alguém que assegura que o pagamento do solicitante do crédito (de um financiamento ou empréstimo) será
            efetuado! <br />
            <br />O avalista só será responsável pelo pagamento das parcelas se o solicitante não efetuar o pagamento e não
            assumir sua responsabilidade como devedor.
          </ModalText>
        </Modal>
      )}
    </>
  )
}

IntroModal.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  setIsShowModal: PropTypes.func.isRequired
}
