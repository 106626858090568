import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const CodeView = styled.div`
  padding-top: 12px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  ${theme.breakpoints.down('md')} {
    justify-content: center;
    width: auto;
  }
`
export const CodeNumber = styled.input.attrs(() => ({
  type: 'tel',
  size: '1',
  maxLength: '1'
}))`
  width: ${theme.variables.spacer * 7}px;
  margin-right: ${theme.variables.spacer * 2}px;
  outline: inherit;
  color: ${theme.colors.blue900};
  background: none;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  border: none;
  border-bottom: 1px solid ${theme.colors.blue900};

  ::placeholder,
  ::-webkit-input-placeholder {
    opacity: 0.5;
    color: ${theme.colors.blue900};
  }
  :-ms-input-placeholder {
    opacity: 0.5;
    color: ${theme.colors.blue900};
  }
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const ContainerComponent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  ${theme.breakpoints.down('md')} {
    align-items: center;
  }
`
