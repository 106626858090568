import React, { useContext } from 'react'
import { Button, Title, SubHeaderDesktop, Container, SubTitle, UploadBox } from '@provi/provi-components'
import { TemplateContext } from '~/components/template/context'
import CNHOpen from '~/assets/svg/cnh-open.svg'
import { usePersonalDocument } from './hooks'

const OPTIONS_DROPZONE = 'image/jpeg, image/png, application/pdf'

export const PersonalDocumentCNH = () => {
  const { handleModal, isMobile, setCurrent, setModal, setIsLoading, goToPage } = useContext(TemplateContext)
  const [
    handleSubmit,
    isButtonDisabled,
    preview,
    setPreview,
    setFilesNotUpload,
    filesNotUpload,
    setNumberOfFiles,
    numberOfFiles,
    numberOfFilesInitial,
    applicationType,
    dropzoneState,
    setDropzoneState
  ] = usePersonalDocument(setModal, setIsLoading, setCurrent, goToPage)

  return (
    <Container>
      {isMobile ? (
        <Title text="Envie sua CNH aberta" />
      ) : (
        <SubHeaderDesktop title="Envie sua CNH aberta" handleModal={handleModal} />
      )}

      <SubTitle text="As informações devem estar legíveis" />

      <UploadBox
        applicationType={applicationType}
        preview={preview}
        setPreview={setPreview}
        setFilesNotUpload={setFilesNotUpload}
        filesNotUpload={filesNotUpload}
        setNumberOfFiles={setNumberOfFiles}
        numberOfFiles={numberOfFiles}
        image={CNHOpen}
        numberOfFilesInitial={numberOfFilesInitial}
        options={OPTIONS_DROPZONE}
        externalStatus={dropzoneState}
        externalStatusChange={setDropzoneState}
      />
      <Button marginHorizontal={0} type="button" disabled={isButtonDisabled} text="Avançar" onClick={() => handleSubmit()} />
    </Container>
  )
}
