/* eslint-disable react/prop-types */
import React from 'react'
import { Route } from 'react-router-dom'

import { Template } from '../components/template'

export default function RouteWrapper(props) {
  const { component: Component, isPrivate, isFirstScreen, isHeader, path, isTemplate, ...rest } = props

  return (
    <>
      <Route
        {...rest}
        render={prop => (
          <>
            {isTemplate ? (
              <Template isFirstScreen={isFirstScreen} isHeader={isHeader}>
                <Component {...prop} />
              </Template>
            ) : (
              <Component {...prop} />
            )}
          </>
        )}
      />
    </>
  )
}

RouteWrapper.defaultProps = {
  isFirstScreen: false,
  isTemplate: true
}
