import React, { useContext } from 'react'
import { Title, SubHeaderDesktop, Container, SubTitle } from '@provi/provi-components'
import { TemplateContext } from '~/components/template/context'
import { FormFormik } from './form'
import { usePhone } from './hooks'

export const Phone = () => {
  const {
    handleModal,
    isMobile,
    setCurrent,
    setIsLoading,
    isLoading,
    checkToken,
    guarantorInfo,
    setGuarantorInfo,
    goToPage
  } = useContext(TemplateContext)

  const [data] = usePhone(setIsLoading, setCurrent, checkToken, guarantorInfo)

  return (
    <Container>
      {!isLoading && data && (
        <>
          {isMobile ? (
            <Title cy="title" text="Qual o número do seu celular?" />
          ) : (
            <SubHeaderDesktop cy="title" title="Qual o número do seu celular?" handleModal={handleModal} />
          )}

          <SubTitle text="Vamos te enviar um código de confirmação" />

          <FormFormik data={data} setIsLoading={setIsLoading} setGuarantorInfo={setGuarantorInfo} goToPage={goToPage} />
        </>
      )}
    </Container>
  )
}
