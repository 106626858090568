import React, { useContext } from 'react'
import { Container, Title, SubHeaderDesktop } from '@provi/provi-components'

import { TemplateContext } from '~/components/template/context'
import { FormFormik } from './form'
import { useCep } from './hooks'

export const Cep = () => {
  const {
    handleModal,
    isMobile,
    setCurrent,
    setIsLoading,
    isLoading,
    checkToken,
    guarantorInfo,
    setGuarantorInfo,
    goToPage
  } = useContext(TemplateContext)

  const [data, isCep] = useCep(setIsLoading, setCurrent, checkToken, guarantorInfo)

  return (
    <Container>
      {!isLoading && data && (
        <>
          {isMobile ? (
            <Title cy="title" text="Qual o seu CEP?" />
          ) : (
            <SubHeaderDesktop cy="title" title="Qual o seu CEP?" handleModal={handleModal} />
          )}

          <FormFormik
            data={data}
            isCep={isCep}
            setIsLoading={setIsLoading}
            setGuarantorInfo={setGuarantorInfo}
            goToPage={goToPage}
          />
        </>
      )}
    </Container>
  )
}
