import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const OptionsList = styled.div`
  display: flex;
  margin-top: ${theme.variables.spacer * 4}px;
  ${({ isMobile }) => isMobile && 'justify-content: space-between'};
`

export const Option = styled.div`
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  padding: 10px;
  height: ${theme.variables.spacer * 13.5}px;
  width: ${theme.variables.spacer * 16}px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  margin-right: ${theme.variables.spacer * 2}px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 20px;
  background-color: ${({ isSelected }) => (isSelected ? theme.colors.blue900 : theme.colors.white100)};
  color: ${({ isSelected }) => (isSelected ? theme.colors.white100 : theme.colors.blue900)};
  ${theme.breakpoints.down('md')} {
    width: 48%;
    margin: 0;
  }
`

export const WrapperTitle = styled.div`
  margin-bottom: 20px;
`
