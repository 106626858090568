import moment from 'moment'
import * as Sentry from '@sentry/browser'
import { setupFirebase } from './services/firebase'

const { REACT_APP_API_ENV } = process.env
const storageName = process.env.FEGUARANTOR_NAME

// sentry active only production.
Sentry.init({
  dsn: 'https://2e11c55aa59344f8885a333dc05c0d80@sentry.io/1868741',
  environment: REACT_APP_API_ENV,
  beforeBreadcrumb(breadcrumb) {
    return breadcrumb.category === 'ui.click' || breadcrumb.category === 'xhr' || breadcrumb.category === 'fetch'
      ? null
      : breadcrumb
  }
})

// initial setup firebase
setupFirebase()

moment.updateLocale('pt-br', require('moment/locale/pt-br'))
