import { useState, useEffect } from 'react'
import { theme } from '@provi/provi-components'
import { showToast } from '~/components/toast'
import { getGuarantorInfo, postSendPhone, postVerifyPhone } from '~/services/api'
import { URL_EXTERNAL } from '~/constants'

const CODE_COUNT = 4

export const useCodeValidator = (setModal, setIsLoading, setCurrent, handleModal, goToPage) => {
  const [data, setData] = useState()

  const inputRefs = []
  const [code, setCode] = useState(new Array(CODE_COUNT).fill(undefined))

  useEffect(() => {
    setIsLoading(true)
    setCurrent(46.66, 2)

    const sendPhone = async () => {
      try {
        const response = await postSendPhone()

        if (response.status === 200) {
          showToast('SMS enviado')
        }
      } catch (error) {
        showToast('Não foi possivel enviar o SMS 😔. Espere alguns minutos antes de tentar novamente')
      }
    }

    const getData = async () => {
      try {
        const response = await getGuarantorInfo('/confirmacao-telefone')
        const { phone, phoneVerified } = response.data

        if (phoneVerified) {
          goToPage('/documentos')
        } else {
          sendPhone()
        }

        setData(phone)
      } catch (error) {
        //
      }

      setIsLoading(false)
    }
    getData()
  }, [setIsLoading])

  const setCodeNumber = async (codeNumber, position) => {
    if (position < code.length) {
      const newCode = code

      newCode[position] = codeNumber
      setCode(newCode)
    }

    const checkArr = code.filter(c => c === undefined || c === '')
    if (checkArr.length <= 0) {
      setIsLoading(true)

      try {
        const response = await postVerifyPhone(code.join(''))
        const { verified } = response.data

        if (verified) {
          goToPage('/documentos')
        }
      } catch (error) {
        showToast('Telefone não foi verificado')
        setIsLoading(false)
      }
    }
  }

  const addInput = ref => {
    inputRefs.push(ref)
  }

  const onKeyDown = (e, position) => {
    if (e.keyCode === 8 && inputRefs[position].value === '' && position !== 0) {
      inputRefs[position - 1].focus()
    } else {
      e.target.select()
    }
  }

  const setNextFocus = (codeNumber, position) => {
    if (codeNumber !== '' && position < CODE_COUNT - 1) {
      inputRefs[position + 1].focus()
      inputRefs[position + 1].select()
    }
  }

  useEffect(() => {
    setModal({
      title: 'Não recebeu seu código?',
      text: [
        'O código de verificação pode demorar até 30 segundos para chegar via SMS no seu celular. Caso não tenha recebido o código de verificação, entre em contato com a gente.'
      ],
      textButton: 'Entendi',
      buttonFunction: () => handleModal(false),
      textLink: {
        color: theme.colors.blue900,
        text: 'Abrir chat',
        onClick: () => window.open(URL_EXTERNAL, '_blank')
      }
    })
  }, [setModal])

  const handleCellphone = async () => {
    try {
      const response = await postSendPhone()

      if (response.status === 200) {
        showToast('SMS enviado')
      }
    } catch (error) {
      showToast('Não foi possivel enviar o SMS 😔. Espere alguns minutos antes de tentar novamente')
    }
  }

  return [code, setCodeNumber, addInput, setNextFocus, onKeyDown, handleCellphone, data]
}
