import React from 'react'

export const PicturePhoto = () => {
  return (
    <svg width="250" height="250" viewBox="0 0 273 273" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M273 0H0V273H273V0Z" fill="#F20045" />
        <path
          d="M184.483 190.724L197.703 201.052C198.564 206.525 200.756 212.765 203.069 218.266C194.435 211.084 187.671 201.186 184.483 190.724Z"
          fill="#DB3A17"
        />
        <path
          d="M204.548 185.291C207.52 177.894 213.586 171.856 220.875 168.668L273 190.724V248.618L203.916 192.727C201.751 185.963 201.899 191.894 204.548 185.291Z"
          fill="#F19C86"
        />
        <path
          d="M54.8422 187.227C54.4253 181.028 55.6894 174.747 58.3791 169.166L96.7874 157.601C101.548 156.175 106.51 155.53 111.473 155.731L148.227 157.143L169.919 153.996C177.222 152.934 184.658 153.849 191.49 156.646L220.875 168.669C213.586 171.869 207.534 177.894 204.548 185.291C201.899 191.894 201.751 199.411 203.916 206.176L197.703 201.052L184.484 190.724C187.671 201.186 194.435 211.071 203.069 218.266C206.539 226.51 193.776 233.14 193.776 233.14H163.249C159.147 231.284 155.328 228.809 151.979 225.783C149.572 223.605 147.07 220.996 143.829 220.808C139.822 220.565 136.729 224.049 133.837 226.819C131.242 229.307 128.297 231.432 125.136 233.14H77.5294C79.9905 226.093 91.045 205.503 92.5915 196.896C95.3619 190.441 92.5915 181.229 92.5915 181.229C83.1374 189.957 72.3653 197.112 60.7056 202.33L59.4684 202.545C56.8325 197.851 55.2053 192.593 54.8422 187.227Z"
          fill="#71AFED"
        />
        <path
          d="M92.6046 196.896L60.7188 202.329C72.3784 197.111 83.1505 189.957 92.6046 181.229C92.6046 181.229 95.375 190.441 92.6046 196.896Z"
          fill="#DB3A17"
        />
        <path
          d="M143.843 220.807C147.084 220.996 149.585 223.605 151.993 225.783C155.328 228.809 159.161 231.284 163.262 233.139H125.15C128.31 231.432 131.255 229.307 133.851 226.819C136.742 224.035 139.835 220.565 143.843 220.807Z"
          fill="#DB3A17"
        />
        <path
          d="M153.835 146.331V120.443H114.687V146.344C97.7015 150.217 74.0729 157.358 54.4653 169.758H114.687H131.268H137.239H153.821H214.043C194.448 157.358 170.82 150.217 153.835 146.331Z"
          fill="#F19C86"
        />
        <path
          d="M153.835 122.595V132.466C150.419 134.147 146.546 135.088 142.498 135.088H126.01C121.949 135.088 118.103 134.147 114.687 132.479V122.608C118.103 124.276 121.949 125.217 126.01 125.217H142.485C146.546 125.231 150.406 124.289 153.835 122.595Z"
          fill="#E5826E"
        />
        <path
          d="M129.305 141.234C131.107 141.247 132.694 141.409 134.294 141.436C135.088 141.489 135.881 141.436 136.688 141.422C137.495 141.395 138.302 141.288 139.217 141.234C137.966 142.579 136.029 143.103 134.254 143.049C133.353 143.009 132.466 142.861 131.618 142.565C130.784 142.269 129.978 141.866 129.305 141.234Z"
          fill="#282C40"
        />
        <path
          d="M100.055 73.8717C100.055 73.8717 94.4605 68.9496 88.207 72.5403C81.9536 76.131 89.8074 98.1996 102.328 94.3399L100.055 73.8717Z"
          fill="#F19C86"
        />
        <path
          d="M99.934 94.7702C88.8392 95.4023 82.3571 75.9023 88.2071 72.5268C90.0495 71.4643 91.8382 71.1416 93.4385 71.2357C92.1206 71.3164 90.722 71.693 89.2695 72.5268C83.6213 75.7947 89.4982 94.1516 99.934 94.7702Z"
          fill="#FCB9A9"
        />
        <path
          d="M90.5337 76.8299C91.9189 76.803 93.2906 77.193 94.5816 77.7175C95.8861 78.2554 97.0561 79.0758 98.132 80.0037C99.1675 80.972 100.136 82.0344 100.875 83.2582C101.279 83.8499 101.575 84.4954 101.924 85.1141C102.234 85.7461 102.476 86.4185 102.758 87.0641L101.292 87.5751C100.674 86.0554 99.6113 84.5492 98.2396 83.7423C97.5537 83.3389 96.7737 83.1641 95.9668 83.1506C95.1733 83.1372 94.2858 83.3254 93.6537 83.6751C92.9947 84.0113 92.672 84.5492 92.8199 85.3158C92.9544 86.0689 93.452 86.822 94.0437 87.4675C95.2137 88.772 96.814 89.7941 98.643 90.1034C96.7602 90.1706 94.8909 89.3368 93.4385 88.0592C92.7258 87.4003 92.0533 86.6203 91.744 85.5579C91.6096 85.0334 91.5558 84.4013 91.8113 83.8365C91.9054 83.5272 92.0802 83.2851 92.2954 83.0699C92.4837 82.8144 92.7123 82.6665 92.9544 82.5185C93.9092 81.9403 94.9044 81.7116 95.9533 81.6713C96.9889 81.6175 98.0916 81.8596 99.0061 82.3841C100.875 83.4465 102.032 85.1679 102.758 87.0641C102.906 87.4675 102.718 87.9113 102.314 88.0592C101.911 88.2072 101.467 88.0189 101.319 87.6154L101.306 87.5751C100.835 86.3379 100.337 85.1141 99.6785 83.971C99.0196 82.8279 98.2665 81.752 97.3789 80.7703C95.5499 78.9279 93.2368 77.3948 90.5337 76.8299Z"
          fill="#282C40"
        />
        <path
          d="M168.103 73.8717C168.103 73.8717 173.697 68.9496 179.951 72.5403C186.204 76.131 178.35 98.1996 165.83 94.3399L168.103 73.8717Z"
          fill="#F19C86"
        />
        <path
          d="M168.238 94.7702C179.333 95.4023 185.815 75.9023 179.965 72.5268C178.122 71.4643 176.334 71.1416 174.733 71.2357C176.051 71.3164 177.45 71.693 178.902 72.5268C184.551 75.7947 178.674 94.1516 168.238 94.7702Z"
          fill="#FCB9A9"
        />
        <path
          d="M177.638 76.8299C176.253 76.803 174.881 77.193 173.59 77.7175C172.286 78.2554 171.116 79.0758 170.04 80.0037C169.004 80.972 168.036 82.0344 167.296 83.2582C166.893 83.8499 166.597 84.4954 166.247 85.1141C165.938 85.7461 165.696 86.4185 165.413 87.0641L166.879 87.5751C167.498 86.0554 168.56 84.5492 169.932 83.7423C170.618 83.3389 171.398 83.1641 172.205 83.1506C172.998 83.1372 173.886 83.3254 174.518 83.6751C175.177 84.0113 175.5 84.5492 175.352 85.3158C175.217 86.0689 174.72 86.822 174.128 87.4675C172.958 88.772 171.358 89.7941 169.529 90.1034C171.411 90.1706 173.281 89.3368 174.733 88.0592C175.446 87.4003 176.118 86.6203 176.428 85.5579C176.562 85.0334 176.616 84.4013 176.36 83.8365C176.266 83.5272 176.091 83.2851 175.876 83.0699C175.688 82.8144 175.459 82.6665 175.217 82.5185C174.262 81.9403 173.267 81.7116 172.218 81.6713C171.183 81.6175 170.08 81.8596 169.166 82.3841C167.296 83.4465 166.14 85.1679 165.413 87.0641C165.266 87.4675 165.454 87.9113 165.857 88.0592C166.261 88.2072 166.705 88.0189 166.852 87.6154L166.866 87.5751C167.337 86.3379 167.834 85.1141 168.493 83.971C169.152 82.8279 169.905 81.752 170.793 80.7703C172.622 78.9279 174.935 77.3948 177.638 76.8299Z"
          fill="#282C40"
        />
        <path
          d="M142.498 125.217H126.024C111.701 125.217 100.082 113.598 100.082 99.2752V35.2749H168.44V99.2752C168.44 113.611 156.82 125.217 142.498 125.217Z"
          fill="#F19C86"
        />
        <path
          d="M168.44 94.4878V99.2754C168.44 113.611 156.82 125.231 142.484 125.231H126.01C111.701 125.231 100.082 113.611 100.082 99.2754V94.4878C100.082 108.824 111.701 120.443 126.01 120.443H142.484C156.82 120.443 168.44 108.824 168.44 94.4878Z"
          fill="#FCB9A9"
        />
        <path
          d="M126.158 77.4086C126.158 80.0982 125.096 82.2903 123.778 82.2903C122.46 82.2903 121.397 80.1117 121.397 77.4086C121.397 74.7189 122.46 72.5269 123.778 72.5269C125.082 72.5269 126.158 74.7189 126.158 77.4086Z"
          fill="#282C40"
        />
        <path
          d="M142.014 77.4086C142.014 80.0982 143.076 82.2903 144.394 82.2903C145.712 82.2903 146.774 80.1117 146.774 77.4086C146.774 74.7189 145.712 72.5269 144.394 72.5269C143.076 72.5269 142.014 74.7189 142.014 77.4086Z"
          fill="#282C40"
        />
        <path
          d="M125.136 92.1479C125.755 92.7935 126.387 93.4121 127.1 93.9366C127.772 94.5014 128.498 94.9855 129.238 95.4159C130.744 96.2497 132.385 96.7876 134.052 96.7473C135.707 96.7204 137.361 96.2228 138.84 95.3486C139.607 94.9586 140.266 94.3669 141.005 93.8828C141.678 93.318 142.31 92.7128 143.022 92.1479C142.498 92.8742 141.92 93.56 141.355 94.2728C140.669 94.8511 140.05 95.5369 139.284 96.0345C137.805 97.1373 135.949 97.7559 134.066 97.8097C132.17 97.8231 130.314 97.1642 128.821 96.1017C127.315 95.0393 126.037 93.7079 125.136 92.1479Z"
          fill="#282C40"
        />
        <path
          d="M131.578 100.795C132.425 101.024 133.246 101.172 134.08 101.159C134.913 101.185 135.734 100.997 136.594 100.795C135.882 101.32 134.981 101.549 134.08 101.562C133.192 101.549 132.277 101.347 131.578 100.795Z"
          fill="#282C40"
        />
        <path
          d="M140.037 60.4905C141.153 59.5088 142.404 59.186 143.722 59.1188C145.026 59.0919 146.371 59.2398 147.648 59.6702C148.939 60.0602 150.123 60.7864 151.105 61.6471C152.14 62.4809 152.866 63.5836 153.472 64.6729C153.781 65.2243 153.579 65.9236 153.028 66.2195C152.584 66.4615 152.059 66.3809 151.71 66.0581L151.616 65.964C150.836 65.2512 150.096 64.5788 149.262 64.0946C148.469 63.5567 147.568 63.2609 146.667 63.0322C145.766 62.8171 144.811 62.7095 143.856 62.7633C142.901 62.7902 141.893 62.844 140.844 62.5884L140.548 62.5212C139.902 62.3598 139.512 61.7143 139.674 61.0822C139.727 60.8536 139.862 60.6519 140.037 60.4905Z"
          fill="#282C40"
        />
        <path
          d="M128.135 60.4905C127.019 59.5088 125.768 59.186 124.45 59.1188C123.146 59.0919 121.801 59.2398 120.524 59.6702C119.232 60.0602 118.049 60.7864 117.067 61.6471C116.032 62.4809 115.306 63.5836 114.7 64.6729C114.391 65.2243 114.593 65.9236 115.144 66.2195C115.588 66.4615 116.112 66.3809 116.462 66.0581L116.556 65.964C117.336 65.2512 118.076 64.5788 118.91 64.0946C119.703 63.5567 120.604 63.2609 121.505 63.0322C122.406 62.8171 123.361 62.7095 124.316 62.7633C125.271 62.7902 126.279 62.844 127.328 62.5884L127.624 62.5212C128.27 62.3598 128.66 61.7143 128.498 61.0822C128.445 60.8536 128.31 60.6519 128.135 60.4905Z"
          fill="#282C40"
        />
        <path
          d="M133.111 69.5815C133.34 71.424 133.488 73.2798 133.622 75.1357C133.784 76.9915 133.797 78.8743 133.434 80.7705C133.219 81.7119 132.856 82.6398 132.331 83.4467C132.076 83.8367 131.82 84.1998 131.726 84.5629C131.632 84.926 131.712 85.2757 131.941 85.6119C132.412 86.2843 133.299 86.6474 134.16 86.7819C135.048 86.9298 135.976 86.8088 136.836 86.4457C135.29 87.4274 132.936 87.535 131.457 85.975C131.107 85.585 130.919 84.9529 131.067 84.4015C131.201 83.8636 131.497 83.4467 131.726 83.0567C132.197 82.3036 132.492 81.4698 132.667 80.5957C133.071 78.8474 133.057 76.9781 133.03 75.1357C133.017 73.3067 133.017 71.4509 133.111 69.5815Z"
          fill="#282C40"
        />
        <path
          d="M162.684 14.0129C143.453 3.85944 102.247 32.8001 102.247 32.8001C101.494 32.2891 100.082 31.8184 98.1857 32.975C95.8053 34.4274 94.5277 37.1574 94.4067 39.9412C93.936 51.0629 100.055 73.8712 100.055 73.8712L102.812 74.8932C103.646 72.4053 105.286 53.4163 105.286 53.4163C106.174 42.2543 114.539 47.1226 114.539 47.1226C114.539 47.1226 134.657 62.7225 152.92 47.9967C154.104 47.0419 155.475 46.3157 156.968 46.1139C159.469 45.7777 162.684 46.7057 163.222 53.4029C163.222 53.4029 164.862 72.3919 165.696 74.8798L168.453 73.8577C168.466 73.8712 181.915 24.1663 162.684 14.0129Z"
          fill="#01163E"
        />
        <path
          d="M102.812 34.3066C103.525 34.8446 104.076 35.5708 104.56 36.3104C105.058 37.0501 105.434 37.857 105.784 38.6773C106.12 39.4977 106.389 40.3449 106.578 41.2056L106.833 42.5101C106.873 42.9539 106.941 43.3842 106.954 43.828C106.268 42.1873 105.703 40.5601 105.044 38.9732C104.722 38.1663 104.385 37.3863 104.022 36.6063C103.673 35.8263 103.283 35.0597 102.812 34.3066Z"
          fill="#1B1E2B"
        />
        <path
          d="M106.967 36.7541C109.549 34.7234 112.306 32.9213 115.13 31.2403C117.955 29.5593 120.886 28.053 123.885 26.6679C126.911 25.3768 129.991 24.1799 133.178 23.3193C136.365 22.4989 139.647 21.9475 142.928 21.9341C139.66 22.2837 136.459 22.9696 133.339 23.8975C130.219 24.8658 127.18 26.0358 124.195 27.3806C121.196 28.6851 118.304 30.1913 115.426 31.7513C112.562 33.3382 109.738 34.9924 106.967 36.7541Z"
          fill="#1B1E2B"
        />
        <path
          d="M108.218 39.0672C110.814 37.951 113.463 36.9692 116.139 36.0413C118.816 35.1134 121.519 34.293 124.235 33.4996C126.965 32.7734 129.709 32.0606 132.492 31.5361C135.276 31.0251 138.087 30.5948 140.924 30.6082C138.114 30.9175 135.357 31.4958 132.613 32.1279C129.87 32.7599 127.14 33.4592 124.437 34.2527C121.72 34.9789 119.017 35.7992 116.314 36.6061L108.218 39.0672Z"
          fill="#1B1E2B"
        />
        <path
          d="M108.877 42.2274C111.002 41.5147 113.194 40.9902 115.399 40.5464C117.605 40.1026 119.824 39.7933 122.07 39.5647C124.316 39.4167 126.561 39.2688 128.821 39.4167C131.067 39.5647 133.326 39.8874 135.451 40.6136C133.245 40.1967 131.026 40.0354 128.807 40.0085C126.588 39.9816 124.356 40.1161 122.15 40.3312C119.931 40.4926 117.726 40.8154 115.507 41.1247C113.288 41.4609 111.082 41.8374 108.877 42.2274Z"
          fill="#1B1E2B"
        />
        <path
          d="M216.019 170.538H53.4971C53.4971 170.538 70.2671 155.247 111.822 146.896C112.764 146.707 113.718 146.533 114.687 146.344C114.687 146.344 131.887 162.388 153.848 146.344C153.848 146.344 155.072 146.492 157.291 146.896C166.032 148.469 190.293 154.037 216.019 170.538Z"
          fill="#5CB5FE"
        />
        <path
          d="M157.277 146.882C136.07 167.243 117.39 152.369 111.822 146.882C112.764 146.694 113.718 146.519 114.687 146.331C114.687 146.331 131.887 162.374 153.848 146.331C153.835 146.331 155.045 146.479 157.277 146.882Z"
          fill="#85C1FF"
        />
        <path d="M214.056 168.722H42.6982V273.014H214.056V168.722Z" fill="#FFEEDE" />
        <path
          d="M28.3085 220.552C28.2951 220.525 28.2816 220.484 28.2682 220.457C26.8158 217.284 28.9809 213.653 32.4506 213.37C38.3006 212.913 51.9506 212.294 51.9506 216.544C51.9506 216.544 51.9506 216.571 51.9506 216.598C51.8296 218.279 50.552 219.651 48.8844 219.906L32.0203 222.475C30.4872 222.717 28.9675 221.937 28.3085 220.552Z"
          fill="#F19C86"
        />
        <path
          d="M29.2769 223.739C28.739 224.963 28.3221 226.55 28.7793 228.096C29.3711 230.06 31.4286 231.176 33.419 230.719C37.9376 229.67 48.898 227.115 50.6462 226.873C52.8652 226.55 53.43 223.134 52.8652 222.004C52.3004 220.875 50.8479 219.974 49.6511 219.732C48.6828 219.53 37.0635 220.902 32.3969 221.453C31.0252 221.601 29.8283 222.475 29.2769 223.739Z"
          fill="#F19C86"
        />
        <path
          d="M33.6479 229.67C32.451 229.992 31.4289 230.746 30.8103 231.808C30.1917 232.87 29.6806 234.269 30.1648 235.574C31.1196 238.169 33.0696 240.186 36.1089 239.433C39.1482 238.68 54.0758 231.848 53.511 229.818C52.9461 227.787 51.9913 226.281 49.9741 226.025C48.3199 225.837 37.6689 228.607 33.6479 229.67Z"
          fill="#F19C86"
        />
        <path
          d="M37.911 238.102C36.7141 238.747 35.6248 239.568 34.6968 240.576C34.0379 241.289 33.473 242.082 33.4999 242.674C33.5672 244.059 36.8486 248.551 41.2865 246.843C45.711 245.135 51.5341 237.927 51.2248 236.085C50.9155 234.336 48.1048 232.628 37.911 238.102Z"
          fill="#F19C86"
        />
        <path
          d="M42.4563 204.777C42.4563 204.777 33.2039 204.4 31.6842 205.342C30.4066 206.149 25.7132 209.511 23.1983 210.708C22.0283 211.273 21.3425 212.564 21.6249 213.828C21.9611 215.28 23.5211 216.719 28.5642 216.343H42.4428V204.777H42.4563Z"
          fill="#F19C86"
        />
        <path
          d="M74.611 184.659H75.8751C77.9461 185.062 78.9682 185.936 78.9682 187.295C78.9682 187.698 78.8337 188.007 78.5782 188.222C78.4303 188.357 78.2286 188.424 77.9999 188.424C77.8386 188.424 77.6637 188.397 77.462 188.33C77.2737 188.317 76.9644 187.94 76.5475 187.187C76.1575 186.958 75.7675 186.837 75.391 186.837H75.1758C73.8444 186.837 72.9299 187.832 72.4189 189.823C72.5668 190.912 73.1989 191.854 74.342 192.634C75.4851 193.414 76.3996 194.194 77.0989 194.987C77.7982 195.767 78.1479 196.883 78.1479 198.309C78.1479 199.855 77.2737 201.119 75.512 202.101L74.4765 202.33C73.1451 202.33 72.1768 201.604 71.5986 200.151V199.586C71.6927 198.9 72.0423 198.551 72.6341 198.551C73.2258 198.551 73.6158 198.806 73.7637 199.304C73.9251 199.801 74.2344 200.057 74.6917 200.057C75.0413 200.057 75.4179 199.559 75.8482 198.564V198.215C75.8482 196.668 74.853 195.377 72.8627 194.315C71.101 192.876 70.2134 191.383 70.2134 189.836C70.2134 187.631 71.4372 185.95 73.8848 184.793L74.611 184.659Z"
          fill="#00165B"
        />
        <path
          d="M85.3563 184.779C86.096 184.873 86.8491 185.411 87.6425 186.38C88.557 188.612 89.0142 190.562 89.0142 192.23C89.0142 196.816 87.8711 200.191 85.5715 202.329C84.9798 202.558 84.5629 202.679 84.3073 202.679C82.0884 202.679 80.9722 199.814 80.9722 194.072C80.9722 190.078 81.8598 187.092 83.6215 185.116C83.9442 184.9 84.5091 184.779 85.3563 184.779ZM84.778 187.079C83.7829 188.962 83.2987 191.14 83.2987 193.615V194.987C83.2987 197.864 83.6484 199.653 84.3342 200.393C85.9346 198.9 86.7415 196.224 86.7415 192.351V192.122C86.7415 190.387 86.3246 188.747 85.4773 187.2L84.9125 187.079H84.778Z"
          fill="#00165B"
        />
        <path
          d="M93.1697 185.129H93.627C94.3128 185.25 94.6625 185.587 94.6625 186.165C94.4339 188.276 94.3128 190.226 94.3128 192.015V195.216C94.3128 197.851 94.6894 199.425 95.456 199.923C96.8277 198.9 97.527 195.686 97.527 190.28C97.7018 187.147 98.0784 185.573 98.6835 185.573H99.4904C99.9477 185.842 100.176 186.192 100.176 186.609V187.537C99.7191 193.588 99.4904 197.112 99.4904 198.094V198.215C99.4904 199.048 99.598 199.976 99.8401 200.971C99.6249 201.657 99.2484 202.007 98.6835 202.007C98.2666 202.007 97.8497 201.698 97.4194 201.092C96.7739 201.859 96.088 202.236 95.3484 202.236C93.8018 202.236 92.7797 200.743 92.2553 197.757C92.0939 196.574 92.0132 195.269 92.0132 193.857C92.0132 188.115 92.3225 185.25 92.9411 185.25L93.1697 185.129Z"
          fill="#00165B"
        />
        <path
          d="M117.551 184.322H117.78C118.614 184.322 119.192 185.317 119.501 187.308L120.873 201.307C120.698 202.141 120.322 202.572 119.73 202.572C119.017 202.572 118.6 202.034 118.466 200.971L118.587 200.406C118.506 200.111 118.466 199.801 118.466 199.478V199.371H118.358C115.978 199.371 114.794 199.6 114.794 200.057C114.646 202.128 114.297 203.15 113.759 203.15H113.302C112.817 203.15 112.468 202.841 112.266 202.222C112.952 197.528 114.135 192.257 115.83 186.393C116.341 185.008 116.919 184.322 117.551 184.322ZM117.336 189.137L115.373 197.394C117.202 197.206 118.13 197.058 118.13 196.937C118.13 196.036 117.901 193.427 117.444 189.137H117.336Z"
          fill="#00165B"
        />
        <path
          d="M123.549 185.237C124.343 185.237 124.84 186.42 125.042 188.801C125.19 189.029 125.567 191.127 126.185 195.108H126.306C127.53 189.433 128.216 186.42 128.377 186.044C128.7 185.734 129.009 185.586 129.292 185.586C130.058 185.761 130.448 186.151 130.448 186.743C129.144 192.391 128.041 197.488 127.127 202.007C126.844 202.235 126.575 202.356 126.32 202.356H126.091C125.163 202.356 124.625 200.944 124.477 198.107C123.643 193.05 122.944 189.191 122.406 186.528V186.407C122.541 185.613 122.917 185.237 123.549 185.237Z"
          fill="#00165B"
        />
        <path
          d="M135.639 184.322H135.868C136.702 184.322 137.28 185.317 137.589 187.308L138.961 201.307C138.786 202.141 138.41 202.572 137.818 202.572C137.105 202.572 136.688 202.034 136.554 200.971L136.675 200.406C136.594 200.111 136.554 199.801 136.554 199.478V199.371H136.446C134.066 199.371 132.882 199.6 132.882 200.057C132.734 202.128 132.385 203.15 131.847 203.15H131.39C130.905 203.15 130.556 202.841 130.354 202.222C131.04 197.528 132.223 192.257 133.918 186.393C134.429 185.008 134.994 184.322 135.639 184.322ZM135.411 189.137L133.447 197.394C135.276 197.206 136.204 197.058 136.204 196.937C136.204 196.036 135.975 193.427 135.518 189.137H135.411Z"
          fill="#00165B"
        />
        <path
          d="M143.022 184.806H143.13C143.816 184.9 144.165 185.25 144.165 185.842C143.789 191.194 143.6 194.368 143.6 195.376C143.6 197.501 143.789 199.223 144.165 200.541H144.407C146.115 200.312 147.03 200.191 147.164 200.191H147.393C147.85 200.46 148.079 200.81 148.079 201.226C148.079 202.128 146.895 202.665 144.515 202.827C143.318 202.827 142.444 202.141 141.879 200.756C141.57 199.276 141.422 197.663 141.422 195.941V194.57C141.664 188.141 142.014 184.927 142.457 184.927L143.022 184.806Z"
          fill="#00165B"
        />
        <path
          d="M152.221 184.779C152.907 184.981 153.256 185.317 153.256 185.815C153.337 191.369 153.404 194.435 153.485 194.987C153.485 199.801 153.256 202.222 152.799 202.222C152.49 202.383 152.261 202.45 152.113 202.45C151.669 202.383 151.333 202.114 151.078 201.643C151.158 198.698 151.199 197.044 151.199 196.708C151.199 195.484 151.158 191.813 151.078 185.694C151.293 185.089 151.683 184.779 152.221 184.779Z"
          fill="#00165B"
        />
        <path
          d="M160.841 184.659H162.105C164.176 185.062 165.198 185.936 165.198 187.295C165.198 187.698 165.064 188.007 164.808 188.222C164.661 188.357 164.459 188.424 164.23 188.424C164.069 188.424 163.894 188.397 163.692 188.33C163.504 188.317 163.195 187.94 162.778 187.187C162.388 186.958 161.998 186.837 161.621 186.837H161.393C160.061 186.837 159.147 187.832 158.636 189.823C158.784 190.912 159.416 191.854 160.559 192.634C161.702 193.414 162.616 194.194 163.316 194.987C164.015 195.767 164.365 196.883 164.365 198.309C164.365 199.855 163.491 201.119 161.729 202.101L160.693 202.33C159.362 202.33 158.394 201.604 157.815 200.151V199.586C157.909 198.9 158.259 198.551 158.851 198.551C159.443 198.551 159.833 198.806 159.981 199.304C160.142 199.801 160.451 200.057 160.908 200.057C161.258 200.057 161.635 199.559 162.065 198.564V198.215C162.065 196.668 161.07 195.377 159.079 194.315C157.318 192.876 156.43 191.383 156.43 189.836C156.43 187.631 157.654 185.95 160.102 184.793L160.841 184.659Z"
          fill="#00165B"
        />
        <path
          d="M174.854 184.322H174.975C175.89 184.403 176.347 184.78 176.347 185.479C176.347 186.165 175.89 186.541 174.975 186.622H173.711C173.334 192.458 173.146 196.278 173.146 198.093V200.85C173.146 201.697 172.797 202.114 172.111 202.114H171.653C171.371 202.114 171.102 201.724 170.86 200.958L170.968 200.393C170.968 200.178 170.927 199.411 170.86 198.093L171.425 186.73H171.304C170.914 186.73 170.309 186.837 169.475 187.079H169.233C168.547 186.958 168.197 186.608 168.197 186.044C168.197 185.048 169.421 184.551 171.869 184.551C172.326 184.403 173.321 184.322 174.854 184.322Z"
          fill="#00165B"
        />
        <path
          d="M181.431 184.322H181.659C182.493 184.322 183.071 185.317 183.381 187.308L184.752 201.307C184.578 202.141 184.201 202.572 183.609 202.572C182.897 202.572 182.48 202.034 182.345 200.971L182.466 200.406C182.385 200.111 182.345 199.801 182.345 199.478V199.371H182.238C179.857 199.371 178.674 199.6 178.674 200.057C178.526 202.128 178.176 203.15 177.638 203.15H177.181C176.697 203.15 176.347 202.841 176.146 202.222C176.831 197.528 178.015 192.257 179.709 186.393C180.22 185.008 180.785 184.322 181.431 184.322ZM181.202 189.137L179.239 197.394C181.068 197.206 181.996 197.058 181.996 196.937C181.996 196.036 181.767 193.427 181.31 189.137H181.202Z"
          fill="#00165B"
        />
        <path
          d="M72.7149 212.738H73.1721C75.808 212.738 77.5024 214.608 78.2152 218.36C78.2959 219.395 78.3362 220.162 78.3362 220.659V222.031C78.3362 224.667 77.4621 227.155 75.7004 229.495C74.8935 230.396 73.6293 231.001 71.908 231.337C71.2759 231.337 70.8859 230.92 70.7514 230.073C70.6707 221.856 70.6304 217.378 70.6304 216.652C70.6573 214.043 71.3431 212.738 72.7149 212.738ZM73.0645 214.917C72.9166 216.194 72.8359 217.27 72.8359 218.131C72.8359 219.113 72.9166 222.623 73.0645 228.688C73.8714 228.379 74.638 227.424 75.3642 225.81C75.9021 224.142 76.1576 222.65 76.1576 221.332V221.224C76.1576 217.028 75.1221 214.917 73.0645 214.917Z"
          fill="#00165B"
        />
        <path
          d="M85.7059 213.317C86.4456 213.411 87.1987 213.949 87.9921 214.917C88.9066 217.15 89.3639 219.1 89.3639 220.767C89.3639 225.353 88.2208 228.729 85.9211 230.867C85.3294 231.096 84.9125 231.217 84.657 231.217C82.438 231.217 81.3218 228.352 81.3218 222.61C81.3218 218.616 82.2094 215.63 83.9711 213.653C84.2938 213.438 84.8721 213.317 85.7059 213.317ZM85.1276 215.603C84.1325 217.486 83.6483 219.664 83.6483 222.139V223.511C83.6483 226.389 83.998 228.177 84.6839 228.903C86.2842 227.411 87.0911 224.734 87.0911 220.875V220.646C87.0911 218.911 86.6742 217.271 85.827 215.711L85.2621 215.59H85.1276V215.603Z"
          fill="#00165B"
        />
        <path
          d="M103.323 217.863C102.476 217.863 101.87 217.056 101.48 215.455V214.89C101.48 213.317 102.207 212.12 103.659 211.327H103.888C104.574 211.421 104.923 211.77 104.923 212.362C104.923 212.658 104.547 213.317 103.78 214.312C103.699 214.729 103.659 215.038 103.659 215.24C104.116 216.007 104.345 216.491 104.345 216.719V216.84C104.251 217.513 103.901 217.863 103.323 217.863ZM108.137 217.863C107.29 217.863 106.685 217.056 106.295 215.455V214.89C106.295 213.317 107.021 212.12 108.474 211.327H108.702C109.388 211.421 109.738 211.77 109.738 212.362C109.738 212.658 109.361 213.317 108.595 214.312C108.514 214.729 108.474 215.038 108.474 215.24C108.931 216.007 109.159 216.491 109.159 216.719V216.84C109.065 217.513 108.729 217.863 108.137 217.863Z"
          fill="#F20045"
        />
        <path
          d="M116.274 212.281C117.807 212.281 118.56 212.698 118.56 213.545V213.774C118.56 214.393 118.25 214.702 117.645 214.702C117.255 214.702 116.798 214.621 116.274 214.46C114.821 214.46 114.095 214.769 114.095 215.388V220.552L116.973 219.974C117.578 220.175 117.887 220.525 117.887 221.009V221.587C117.887 222.098 116.664 222.475 114.216 222.731C114.283 224.264 114.324 225.407 114.324 226.173V230.315C114.202 231.149 113.786 231.58 113.059 231.58C112.374 231.351 112.024 230.894 112.024 230.208C111.943 222.556 111.903 217.472 111.903 214.944C111.916 213.169 113.369 212.281 116.274 212.281Z"
          fill="#F20045"
        />
        <path
          d="M122.716 213.667H123.173C123.859 213.788 124.208 214.124 124.208 214.702C123.98 216.813 123.859 218.763 123.859 220.552V223.753C123.859 226.389 124.235 227.962 125.002 228.46C126.374 227.438 127.073 224.223 127.073 218.817C127.248 215.684 127.624 214.11 128.229 214.11H129.036C129.494 214.379 129.722 214.729 129.722 215.146V216.074C129.265 222.125 129.036 225.649 129.036 226.631V226.752C129.036 227.585 129.144 228.513 129.386 229.509C129.171 230.194 128.794 230.544 128.229 230.544C127.813 230.544 127.396 230.235 126.965 229.63C126.32 230.396 125.634 230.773 124.894 230.773C123.348 230.773 122.326 229.28 121.801 226.294C121.64 225.111 121.559 223.806 121.559 222.394C121.559 216.652 121.868 213.788 122.487 213.788L122.716 213.667Z"
          fill="#F20045"
        />
        <path
          d="M134.335 213.33H134.442C135.128 213.424 135.478 213.774 135.478 214.366C135.101 219.718 134.913 222.892 134.913 223.9C134.913 226.025 135.101 227.747 135.478 229.065H135.72C137.428 228.836 138.342 228.715 138.477 228.715H138.705C139.163 228.984 139.391 229.334 139.391 229.75C139.391 230.651 138.208 231.189 135.827 231.351C134.631 231.351 133.756 230.665 133.192 229.28C132.882 227.8 132.734 226.187 132.734 224.465V223.094C132.976 216.665 133.326 213.451 133.77 213.451L134.335 213.33Z"
          fill="#F20045"
        />
        <path
          d="M147.648 212.859H147.877C148.711 212.859 149.289 213.855 149.598 215.845L150.97 229.845C150.795 230.678 150.419 231.109 149.827 231.109C149.114 231.109 148.697 230.571 148.563 229.508L148.684 228.944C148.603 228.648 148.563 228.338 148.563 228.016V227.908H148.455C146.075 227.908 144.892 228.137 144.892 228.594C144.744 230.665 144.394 231.687 143.856 231.687H143.399C142.915 231.687 142.565 231.378 142.363 230.759C143.049 226.066 144.233 220.794 145.927 214.93C146.438 213.545 147.016 212.859 147.648 212.859ZM147.433 217.674L145.47 225.931C147.299 225.743 148.227 225.595 148.227 225.474C148.227 224.573 147.998 221.964 147.541 217.674H147.433Z"
          fill="#F20045"
        />
        <path
          d="M155.583 212.859H155.691C156.605 212.859 157.52 214.89 158.448 218.938C159.214 220.996 159.712 222.636 159.941 223.874H160.062C160.586 217.069 160.936 213.666 161.097 213.666C161.501 213.276 161.877 213.088 162.24 213.088C162.859 213.29 163.168 213.639 163.168 214.124L161.796 230.315C161.474 230.611 161.164 230.772 160.868 230.772H160.519C159.819 230.772 159.201 229.629 158.69 227.33C157.237 222.435 156.511 219.906 156.511 219.758H156.39C156.242 223.645 156.162 226.281 156.162 227.679V229.522C156.162 230.356 155.812 230.786 155.126 230.786H154.669C154.212 230.544 153.983 230.167 153.983 229.643C153.983 218.615 154.292 213.115 154.911 213.115C155.153 212.927 155.382 212.859 155.583 212.859Z"
          fill="#F20045"
        />
        <path
          d="M170.013 213.317C170.753 213.411 171.506 213.949 172.299 214.917C173.214 217.15 173.671 219.1 173.671 220.767C173.671 225.353 172.528 228.729 170.228 230.867C169.636 231.096 169.22 231.217 168.964 231.217C166.745 231.217 165.629 228.352 165.629 222.61C165.629 218.616 166.516 215.63 168.278 213.653C168.601 213.438 169.179 213.317 170.013 213.317ZM169.435 215.603C168.44 217.486 167.955 219.664 167.955 222.139V223.511C167.955 226.389 168.305 228.177 168.991 228.903C170.591 227.411 171.398 224.734 171.398 220.875V220.646C171.398 218.911 170.981 217.271 170.134 215.711L169.569 215.59H169.435V215.603Z"
          fill="#F20045"
        />
        <path
          d="M177.678 211.327C178.526 211.327 179.144 212.134 179.521 213.734V214.299C179.521 215.872 178.795 217.069 177.342 217.863H177.114C176.428 217.768 176.078 217.419 176.078 216.827C176.078 216.531 176.468 215.872 177.235 214.877C177.302 214.46 177.342 214.151 177.342 213.949C176.885 213.183 176.656 212.698 176.656 212.47V212.349C176.751 211.663 177.1 211.327 177.678 211.327ZM182.506 211.327C183.354 211.327 183.972 212.134 184.349 213.734V214.299C184.349 215.872 183.623 217.069 182.17 217.863H181.942C181.256 217.768 180.906 217.419 180.906 216.827C180.906 216.531 181.296 215.872 182.063 214.877C182.13 214.46 182.17 214.151 182.17 213.949C181.713 213.183 181.484 212.698 181.484 212.47V212.349C181.578 211.663 181.915 211.327 182.506 211.327Z"
          fill="#F20045"
        />
        <path
          d="M90.7891 241.383H90.8967C91.8112 241.383 92.7256 243.414 93.6536 247.462C94.4201 249.519 94.9177 251.16 95.1463 252.397H95.2674C95.7918 245.593 96.1415 242.19 96.3029 242.19C96.7063 241.8 97.0829 241.612 97.446 241.612C98.0646 241.814 98.3739 242.163 98.3739 242.647L97.0022 258.839C96.6794 259.135 96.3701 259.296 96.0743 259.296H95.7246C95.0253 259.296 94.4067 258.153 93.8956 255.854C92.4432 250.958 91.717 248.43 91.717 248.282H91.5825C91.4346 252.169 91.3539 254.805 91.3539 256.203V258.046C91.3539 258.88 91.0043 259.31 90.3184 259.31H89.8612C89.4039 259.068 89.1753 258.691 89.1753 258.167C89.1753 247.139 89.4846 241.639 90.1032 241.639C90.3453 241.464 90.5739 241.383 90.7891 241.383Z"
          fill="#00165B"
        />
        <path
          d="M105.206 241.383H105.434C106.268 241.383 106.846 242.378 107.156 244.369L108.527 258.368C108.352 259.202 107.976 259.633 107.384 259.633C106.671 259.633 106.255 259.095 106.12 258.032L106.241 257.467C106.16 257.172 106.12 256.862 106.12 256.539V256.432H106.012C103.632 256.432 102.449 256.661 102.449 257.118C102.301 259.189 101.951 260.211 101.413 260.211H100.956C100.472 260.211 100.122 259.902 99.9204 259.283C100.606 254.589 101.79 249.318 103.484 243.454C103.995 242.083 104.56 241.383 105.206 241.383ZM104.977 246.211L103.014 254.468C104.842 254.28 105.77 254.132 105.77 254.011C105.77 253.11 105.542 250.501 105.085 246.211H104.977Z"
          fill="#00165B"
        />
        <path
          d="M122.473 240.818H122.823C125.096 240.818 126.817 242.082 127.987 244.611C128.296 245.445 128.444 246.332 128.444 247.247C128.444 250.017 127.14 251.9 124.544 252.868C124.02 252.935 123.603 252.976 123.28 252.976H122.823V258.489C122.729 259.175 122.379 259.525 121.787 259.525C121.021 259.417 120.631 259.041 120.631 258.368V249.869C120.765 244.059 121.034 241.155 121.438 241.155C121.734 240.939 122.083 240.818 122.473 240.818ZM123.159 243.226L123.038 243.911V244.369C123.038 245.081 122.957 247.193 122.809 250.689L123.038 250.81C125.028 250.555 126.024 249.681 126.024 248.161C126.091 248.161 126.131 247.932 126.131 247.475V246.91C126.131 244.839 125.136 243.589 123.146 243.118V243.226H123.159Z"
          fill="#00165B"
        />
        <path
          d="M134.886 240.926C136.702 240.926 138.046 242.15 138.907 244.611C139.136 245.404 139.257 246.211 139.257 247.018C139.257 249.089 138.45 250.837 136.85 252.29H136.729V252.532C138.181 253.325 138.907 255.046 138.907 257.696C138.907 259.296 138.49 260.103 137.643 260.103C137.119 260.103 136.729 259.713 136.5 258.946C136.567 258.328 136.648 257.938 136.729 257.803C136.729 255.961 136.271 254.724 135.357 254.132C134.644 253.823 134.146 253.675 133.864 253.675H133.756V258.503C133.756 259.417 133.407 259.874 132.721 259.874H132.479C131.901 259.874 131.551 259.484 131.443 258.718V247.018C131.443 244.019 131.941 242.19 132.936 241.504C133.434 241.114 134.093 240.926 134.886 240.926ZM133.743 245.982V250.797C133.743 250.918 133.891 251.066 134.2 251.254C136.11 250.353 137.078 248.941 137.078 247.018C137.078 245.821 136.688 244.664 135.922 243.575C135.169 243.32 134.792 243.172 134.779 243.118C134.079 243.104 133.743 244.073 133.743 245.982Z"
          fill="#00165B"
        />
        <path
          d="M146.613 241.854C147.353 241.948 148.106 242.486 148.899 243.454C149.814 245.687 150.271 247.637 150.271 249.304C150.271 253.89 149.128 257.266 146.828 259.404C146.237 259.633 145.82 259.754 145.564 259.754C143.345 259.754 142.229 256.889 142.229 251.147C142.229 247.153 143.117 244.167 144.878 242.19C145.201 241.962 145.779 241.854 146.613 241.854ZM146.035 244.14C145.04 246.023 144.556 248.202 144.556 250.676V252.048C144.556 254.926 144.905 256.714 145.591 257.441C147.191 255.948 147.998 253.272 147.998 249.412V249.17C147.998 247.435 147.581 245.794 146.734 244.248L146.169 244.127H146.035V244.14Z"
          fill="#00165B"
        />
        <path
          d="M153.525 242.298C154.319 242.298 154.816 243.481 155.018 245.862C155.166 246.09 155.543 248.188 156.161 252.182H156.282C157.506 246.507 158.192 243.495 158.353 243.118C158.676 242.809 158.985 242.661 159.268 242.661C160.034 242.836 160.424 243.226 160.424 243.818C159.12 249.466 158.017 254.563 157.103 259.081C156.82 259.31 156.551 259.431 156.296 259.431H156.067C155.139 259.431 154.601 258.019 154.453 255.181C153.62 250.125 152.92 246.265 152.382 243.602V243.481C152.503 242.688 152.893 242.298 153.525 242.298Z"
          fill="#00165B"
        />
        <path
          d="M164.553 241.854C165.239 242.056 165.588 242.392 165.588 242.89C165.669 248.444 165.736 251.51 165.817 252.061C165.817 256.876 165.588 259.296 165.131 259.296C164.822 259.458 164.593 259.538 164.445 259.538C164.001 259.471 163.665 259.202 163.41 258.732C163.49 255.786 163.531 254.132 163.531 253.796C163.531 252.572 163.49 248.901 163.41 242.782C163.638 242.15 164.015 241.854 164.553 241.854Z"
          fill="#00165B"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="273" height="273" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
