/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { Modal, Button } from '@provi/provi-components'
import { TemplateContext } from '~/components/template/context'

import VideoRecorder from '~/components/video'

import {
  Container,
  Image,
  Text,
  IconContainer,
  Wrapper,
  WrapperButton,
  WrapperWebcam,
  ContainerComplete,
  ContainerUploadInfo,
  TitleUploadInfo,
  LinkText
} from '../styles'

import { IconCheckUpload } from '~/assets/svg/icon-check'

import { IconUpload } from '~/assets/svg/upload'

import { useVideoUpload } from '../hooks'

export const WebcamVideo = props => {
  const { setIsLoading } = useContext(TemplateContext)
  const {
    isWebcamModal,
    setisWebcamModal,
    img,
    disabled,
    setIsButtonFileDisable,
    setIsButtonDisabled,
    urls,
    setWebcam,
    setFilesNotUpload,
    filesNotUpload
  } = props

  const [handleVideo, setVideo, status, isButtonModalDisabled, setIsButtonModalDisabled, completeClick] = useVideoUpload(
    setisWebcamModal,
    setIsButtonFileDisable,
    setIsButtonDisabled,
    urls,
    setIsLoading,
    setWebcam,
    setFilesNotUpload,
    filesNotUpload
  )

  if (disabled) {
    return (
      <Container disabled>
        <Image style={{ zIndex: 1 }} src={img} margin />
        <Text style={{ zIndex: 1 }}>Gravar vídeo</Text>
        <IconContainer>
          <IconUpload />
        </IconContainer>
      </Container>
    )
  }

  return (
    <>
      {isWebcamModal && (
        <Modal title="Gravar vídeo" onDismiss={() => setisWebcamModal(false)}>
          <Wrapper>
            <WrapperWebcam>
              <VideoRecorder
                onRecordingComplete={videoBlob => {
                  setIsButtonModalDisabled(false)
                  setVideo(videoBlob)
                }}
              />
            </WrapperWebcam>
            <WrapperButton>
              <Button text="Salvar vídeo" disabled={isButtonModalDisabled} onClick={() => handleVideo()} />
            </WrapperButton>
          </Wrapper>
        </Modal>
      )}

      {status !== 'complete' ? (
        <Container onClick={() => setisWebcamModal(true)}>
          <Image style={{ zIndex: 1 }} src={img} margin />
          <Text style={{ zIndex: 1 }}>Gravar vídeo</Text>
          <IconContainer>
            <IconUpload />
          </IconContainer>
        </Container>
      ) : (
        <ContainerComplete onClick={() => completeClick()}>
          <Image withoutPreview style={{ zIndex: 1 }} src={img} margin />
          <IconContainer>
            <IconUpload />
          </IconContainer>
          <ContainerUploadInfo>
            <TitleUploadInfo>
              Upload concluído <IconCheckUpload />
            </TitleUploadInfo>
            <LinkText>Gravar outro</LinkText>
          </ContainerUploadInfo>
        </ContainerComplete>
      )}
    </>
  )
}
