import React, { useContext } from 'react'
import { LoadingProvi, Emoji } from '@provi/provi-components'
import { TemplateContext } from '~/components/template/context'
import { ContainerBackground, ContainerSuccess, ContainerText, Wrapper, Container, Title, SubTitle, Text } from './style'
import { useSuccess } from '~/screens/success-information/hooks'

export const SuccessInformation = () => {
  const { isMobile, isLoading, setIsLoading } = useContext(TemplateContext)
  const [name] = useSuccess(setIsLoading)

  return (
    <>
      <Wrapper>
        <ContainerSuccess>
          {isLoading && <LoadingProvi isLoadingDark />}
          <Container>
            <ContainerText>
              <Title>
                Você concluiu o envio das suas informações para a análise! <Emoji symbol="🙌" />
              </Title>
              <Text>
                Seus documentos e dados pessoais já estão sendo analisados para que você ajude{' '}
                {name === 'Null' || !name ? 'uma pessoa' : name} a transformar a carreira.
              </Text>
              <SubTitle>
                Assim que a análise for concluída, você e {name === 'Null' || !name ? 'uma pessoa' : name} receberão uma
                confirmação via email.
              </SubTitle>
            </ContainerText>
          </Container>
        </ContainerSuccess>
        {!isMobile && <ContainerBackground />}
      </Wrapper>
    </>
  )
}
