import React from 'react'

import * as Yup from 'yup'
import { withFormik } from 'formik'
import PropTypes from 'prop-types'

import { Button, Input, InputMasks, Form } from '@provi/provi-components'

import { TextErrorInput } from '~/components/text-error-input'

import { validateText, validateState } from '~/utils/validators'
import { Modal } from './Modal'
import { useForm, usePersonalDataValidator } from './hooks'
import { putGuarantorInfo } from '~/services/api'
import { logger } from '~/helpers/logger'
import { showAllToasts } from '~/utils'

export const Formik = props => {
  const { handleChange, setValues, values, errors, touched, handleSubmit, handleBlur, setFieldValue, isCep, data } = props

  const [isShowAddress, getCep] = usePersonalDataValidator(setValues, values, data)
  const [isShowModal, setIsShowModal, modal] = useForm()

  return (
    <Form onSubmit={handleSubmit}>
      {isShowModal && <Modal setIsShowModal={setIsShowModal} modal={modal} />}
      <InputMasks
        id="zipcode"
        InputProps={{ 'aria-label': 'zipcode', cy: 'input-zipcode' }}
        mask="99999-999"
        placeholder="Ex: 04548-004"
        value={values.zipcode}
        onChange={async e => {
          const { value } = e.target
          const _zipcode = value && value.replace(/[^\d]+/g, '')
          setFieldValue('zipcode', _zipcode)

          if (_zipcode && _zipcode.length === 8) {
            try {
              const address = await getCep(_zipcode)
              setFieldValue('zipcode', address.zipcode)
              setFieldValue('city', address.city)
              setFieldValue('state', address.state)
              setFieldValue('district', address.district)
              setFieldValue('street', address.street)
              return true
            } catch (err) {
              return false
            }
          }
          return false
        }}
      />
      {errors.zipcode && touched.zipcode && <TextErrorInput>{errors.zipcode}</TextErrorInput>}

      {(isShowAddress || isCep) && (
        <>
          <Input
            id="state"
            inputProps={{ cy: 'input-state', 'aria-label': 'uf', maxLength: 2 }}
            value={values.state}
            placeholder="UF"
            onBlur={handleBlur('state')}
            onChange={handleChange('state')}
            style={{ textTransform: 'uppercase' }}
          />
          {errors.state && <TextErrorInput>{errors.state}</TextErrorInput>}

          <Input
            id="city"
            inputProps={{ 'aria-label': 'city', cy: 'input-city' }}
            onBlur={handleBlur('city')}
            onChange={handleChange('city')}
            placeholder="Cidade"
            value={values.city}
          />
          {errors.city && touched.city && <TextErrorInput>{errors.city}</TextErrorInput>}

          <Input
            id="district"
            inputProps={{ 'aria-label': 'district', cy: 'input-district' }}
            placeholder="Bairro"
            onBlur={handleBlur('district')}
            onChange={handleChange('district')}
            value={values.district}
          />
          {errors.district && touched.district && <TextErrorInput>{errors.district}</TextErrorInput>}

          <Input
            id="street"
            inputProps={{ 'aria-label': 'street', cy: 'input-street' }}
            placeholder="Endereço"
            onBlur={handleBlur('street')}
            onChange={handleChange('street')}
            value={values.street}
          />
          {errors.street && touched.street && <TextErrorInput>{errors.street}</TextErrorInput>}

          <Input
            id="number"
            inputProps={{ 'aria-label': 'number', cy: 'input-number' }}
            onBlur={handleBlur('number')}
            onChange={handleChange('number')}
            placeholder="Número"
            value={values.number}
          />
          {errors.number && touched.number && <TextErrorInput>{errors.number}</TextErrorInput>}

          <Input
            id="complement"
            inputProps={{ 'aria-label': 'complement', cy: 'input-complement' }}
            placeholder="Complemento (opcional)"
            onBlur={handleBlur('complement')}
            onChange={handleChange('complement')}
            value={values.complement}
          />
        </>
      )}

      <Button
        marginHorizontal={0}
        marginVertical={24}
        cy="button"
        type="submit"
        disabled={
          !(values.zipcode && values.city && values.state && values.district && values.street && values.number) ||
          errors.zipcode ||
          errors.city ||
          errors.state ||
          errors.district ||
          errors.street ||
          errors.number
        }
        text="Avançar"
      />
    </Form>
  )
}
export const FormFormik = withFormik({
  mapPropsToValues: props => {
    const { data } = props
    return {
      zipcode: data.cep ? data.cep : '',
      city: data.city ? data.city : '',
      state: data.state ? data.state : '',
      district: data.district ? data.district : '',
      street: data.street ? data.street : '',
      number: data.number ? data.number : '',
      complement: data.complement ? data.complement : ''
    }
  },
  validationSchema: () =>
    Yup.object().shape({
      city: Yup.string()
        .required('Digite uma cidade')
        .test({
          name: 'city',
          message: 'Cidade invalida',
          test: city => city && validateText(city)
        }),
      state: Yup.string()
        .required('Digite um estado')
        .test({
          name: 'state',
          message: 'Estado invalida',
          test: state => state && validateState(state)
        }),
      district: Yup.string().required('Digite um bairro'),
      street: Yup.string().required('Digite um endereço'),
      number: Yup.string().required('Digite um número')
    }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { setIsLoading, setGuarantorInfo, goToPage } = props
    // data

    setSubmitting(false)

    try {
      setIsLoading(true)

      try {
        setGuarantorInfo(oldValues => ({ ...oldValues, address: { ...values } }))
      } catch (err) {
        logger({ error: err, ref: 'cache context (cep)' })
      }

      await putGuarantorInfo(values, '/cep')

      goToPage('/email')
    } catch (error) {
      setIsLoading(false)
      showAllToasts(error)
    }
  }
})(Formik)

Formik.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.string).isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  touched: PropTypes.oneOfType([PropTypes.any]).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  isCep: PropTypes.bool.isRequired,
  data: PropTypes.objectOf(PropTypes.string).isRequired
}
