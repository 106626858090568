import React, { useContext } from 'react'
import { Button, Container } from '@provi/provi-components'
import { isUndefined } from 'lodash'
import { TemplateContext } from '~/components/template/context'
import { Terms } from './terms'
import { Content, Title, RedTitle, Text, Bold, Section } from './styles'
import { useResume } from './hooks'

export const Resume = () => {
  const { setCurrent, setIsLoading, creditRequestInfo, setCreditRequestInfo, ip, checkToken, goToPage } = useContext(
    TemplateContext
  )
  const [onSubmit, data] = useResume(
    setIsLoading,
    setCurrent,
    creditRequestInfo,
    setCreditRequestInfo,
    ip,
    checkToken,
    goToPage
  )

  return (
    <>
      {data && (
        <Container>
          <Content>
            <Title cy="title">
              {data.guarantorName === 'Null Null' || !data.guarantorName ? 'Avalista' : data.guarantorName},
            </Title>
            <Section cy="sec-1">
              <RedTitle cy="red-1">Quem está te convidando para ser avalista:</RedTitle>
              <Text>
                Nome:<Bold>{data.userFullName}</Bold>
              </Text>
              <Text>
                CPF:<Bold>{data.cpf}</Bold>
              </Text>
              <Text>
                Data de nascimento:<Bold>{data.birthDate}</Bold>
              </Text>
            </Section>
            {data.partner && ((data.courses && data.courses.length) || data.course) && (
              <Section cy="sec-2">
                <RedTitle cy="red-2">Detalhes da finalidade dos recursos:</RedTitle>
                <Text>
                  Parceiro:<Bold>{data.partner}</Bold>
                </Text>
                {data.courses && data.courses.length > 0 ? (
                  <>
                    {data.courses.map((c, i) => (
                      <Text>
                        Produto {i + 1}:<Bold>{c}</Bold>
                      </Text>
                    ))}
                  </>
                ) : (
                  <Text>
                    Produto:<Bold>{data.course}</Bold>
                  </Text>
                )}
              </Section>
            )}
            {data.creditRequest &&
              !data.userReceivesMoney &&
              (data.upfrontValue || data.numberOfInstallments || data.pmt || data.takenAmount || data.monthlyInterest) && (
                <Section cy="sec-3">
                  <RedTitle cy="red-3">Detalhes do financiamento:</RedTitle>
                  {data.upfrontValue !== 0 && data.upfrontValue && (
                    <Text>
                      Entrada:<Bold>{data.upfrontValue}</Bold>
                    </Text>
                  )}
                  {data.numberOfInstallments && (
                    <Text>
                      Número de parcelas:<Bold>{data.numberOfInstallments}x</Bold>
                    </Text>
                  )}
                  {data.pmt && (
                    <Text>
                      Valor das parcelas:<Bold>{data.pmt}</Bold>
                    </Text>
                  )}
                  {data.takenAmount && (
                    <Text>
                      Valor do financiamento:<Bold>{data.takenAmount}</Bold>
                    </Text>
                  )}
                  {isUndefined(data.monthlyInterest) === false && (
                    <Text>
                      Taxa de juros mensais:<Bold>{data.monthlyInterest} %</Bold>
                    </Text>
                  )}
                </Section>
              )}
            <Button onClick={onSubmit} marginHorizontal={0} text="Avançar" />
            <Terms />
          </Content>
        </Container>
      )}
    </>
  )
}
