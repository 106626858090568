/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react'
import { Button, Modal as ModalComponent } from '@provi/provi-components'
import { Text } from './styles'

const URL_EXTERNAL = 'https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20?'

export const Modal = ({ setIsShowModal }) => {
  return (
    <>
      <ModalComponent
        title="Meus dados estão seguros?"
        footer={
          <Button
            type="button"
            text="Entendi"
            textLink={{ text: 'Abrir chat', onClick: () => window.open(URL_EXTERNAL, '_blank') }}
            onClick={() => setIsShowModal(false)}
          />
        }
        onDismiss={() => setIsShowModal(false)}
      >
        <Text>
          A Provi pede essas informações para que não haja fraude em seus processos de financiamento. Asseguramos seus dados e
          do solicitante de crédito.
        </Text>
      </ModalComponent>
    </>
  )
}
