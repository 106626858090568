import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { InputMasks, Button, Form, theme } from '@provi/provi-components'
import { TemplateContext } from '~/components/template/context'
import { TextErrorInput } from '~/components/text-error-input'
import { Container } from './styles'
import { useForm } from './hooks'

export const FormWrapper = props => {
  const { setIsLoading, setToken, goToPage } = useContext(TemplateContext)

  const { setIsShowModal, urlToken } = props
  const [onSubmit, onFormChange, isButtonDisabled, isShowError, value, onBlur] = useForm(
    setIsLoading,
    setToken,
    urlToken,
    goToPage
  )

  return (
    <Form onSubmit={onSubmit} autoComplete="off">
      <Container>
        <InputMasks
          id="token"
          inputProps={{ style: { color: 'white' }, 'aria-label': 'token', cy: 'input' }}
          mask="999.999.999-99"
          placeholder="Ex: 012.345.678-90"
          onBlur={onBlur}
          autoFocus
          onChange={onFormChange}
        />
      </Container>
      {isShowError && <TextErrorInput>{!value.token ? 'Preencha o campo' : 'CPF inválido'}</TextErrorInput>}

      <Button
        id="about-guarantor-button"
        type="submit"
        inputProps={{ cy: 'button' }}
        marginHorizontal={0}
        marginVertical={24}
        disabled={isButtonDisabled}
        text="Avançar"
        textLink={{ color: theme.colors.grey600, text: ' O que é um avalista?', onClick: () => setIsShowModal(true) }}
        backgroundDisabled
      />
    </Form>
  )
}

FormWrapper.propTypes = {
  setIsShowModal: PropTypes.func.isRequired,
  urlToken: PropTypes.string.isRequired
}
