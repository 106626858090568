import styled, { keyframes } from 'styled-components'
import { theme } from '@provi/provi-components'

export const Text = styled.div`
  font-family: 'CenturyGothic', sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: normal;
  color: ${theme.colors.blue900};
`

export const Bold = styled.span`
  font-family: 'CenturyGothicBold', sans-serif;
  color: ${theme.colors.blue900};
`

export const Content = styled.div`
  max-width: 600px;
`

export const animationWarning = keyframes`
  0% {
    bottom: -257px;
  }
  100% {
    bottom: 0;
  }
`

export const WarningAnimation = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
`

export const Warning = styled.div`
  padding: 0 20px;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  height: 257px;
  border-radius: 30px 30px 0 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  animation-name: ${animationWarning};
  animation-duration: 500ms;
  animation-timing-function: ease;
  background-color: ${theme.colors.blue900};
  color: ${theme.colors.grey400};
`

export const BoldWarning = styled.div`
  font-family: 'CenturyGothicBold';
  font-size: 24px;
  line-height: 30px;
`

export const Section = styled.div``
