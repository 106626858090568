import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: ${theme.variables.spacer * 3}px;
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
`

export const List = styled.li`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: ${theme.variables.spacer * 3}px;
  margin-top: ${({ first }) => first && theme.variables.spacer * 3}px;
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`
export const ActionText = styled.div`
  font-size: 20px;
  line-height: 30px;
`

export const StepText = styled.div`
  font-family: 'CenturyGothicBold';
  font-size: 20px;
  line-height: 30px;
  color: ${theme.colors.pink900};
`

export const StepTitle = styled.div`
  font-family: 'CenturyGothicBold';
  font-size: 20px;
  line-height: 30px;
  color: ${theme.colors.blue900};
  /* margin-bottom: ${theme.variables.spacer * 3}px; */
`
