import { useEffect, useState } from 'react'
import { getCreditRequestInfo } from '~/services/api'

export const useSuccess = setIsLoading => {
  const [name, setName] = useState('')
  useEffect(() => {
    const getName = async () => {
      try {
        const response = await getCreditRequestInfo('/sucesso')
        const { user } = response.data
        const { firstName } = user
        setName(firstName)
      } catch (err) {
        //
      }
      setIsLoading(false)
    }
    getName()
  }, [])

  return [name]
}
