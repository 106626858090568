import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal as ModalComponent } from '@provi/provi-components'
import { VideoGif } from '~/components/video-gif'
import { PicturePhoto } from '~/assets/svg/picturePhoto'
import { Text, ImageWrapper, ActionText, StepText, StepTitle, List } from './style'
import { useModal } from './hooks'

export const Modal = ({ handleModalButton, setIsShowModal, asset, name }) => {
  const [handleFirstStepButton, next] = useModal()
  const data =
    asset === 'video'
      ? {
          stepOne: {
            title: 'Tenha certeza de que:',
            texts: [
              'Você está em um local bem iluminado',
              'Não tem nenhum ruído atrapalhando a gravação',
              'O microfone está ligado'
            ],
            action: false
          },
          title: 'Grave o vídeo selfie dizendo:',
          img: <VideoGif />,
          text: `"Sou avalista de ${name === 'Null' || !name ? 'fulano' : name} na Provi"`,
          textButton: 'Gravar vídeo selfie'
        }
      : {
          stepOne: {
            title: 'Escreva em um papel em letras de forma:',
            texts: [],
            action: `Sou avalista de ${name === 'Null' || !name ? 'fulano' : name} na Provi`
          },
          title: 'Segure o papel e tire uma selfie:',
          img: <PicturePhoto />,
          text: 'Tire uma foto de você mesmo, segurando o papel escrito em letras de forma.',
          textButton: 'Tirar selfie'
        }

  return (
    <>
      <ModalComponent
        footer={
          <Button
            type="button"
            cy={`cy-${data.title}`}
            text={next ? data.textButton : 'Próximo passo'}
            onClick={!next ? handleFirstStepButton : handleModalButton}
          />
        }
        onDismiss={() => setIsShowModal(false)}
      >
        {!next ? (
          <>
            <StepText>1° passo</StepText>
            <StepTitle>{data.stepOne.title}</StepTitle>
            {data.stepOne.texts && data.stepOne.texts.map((txt, idx) => <List first={idx === 0}>{txt}</List>)}

            {data.stepOne.action && <ActionText>{data.stepOne.action}</ActionText>}
          </>
        ) : (
          <>
            <StepText>2° passo</StepText>
            <StepTitle>{data.title}</StepTitle>
            <Text>{data.text}</Text>
            <ImageWrapper>{data.img}</ImageWrapper>
          </>
        )}
      </ModalComponent>
    </>
  )
}

Modal.defaultProps = {
  asset: 'photo'
}

Modal.propTypes = {
  handleModalButton: PropTypes.func.isRequired,
  setIsShowModal: PropTypes.func.isRequired,
  asset: PropTypes.string,
  name: PropTypes.string.isRequired
}
