import React from 'react'
import { termsAndConditionsLink, privacyPolicyLink } from '~/constants'
import { TextTerms, TextLinkExternal, ContainerTerms } from './styles'

export const Terms = () => {
  return (
    <ContainerTerms>
      <TextTerms bold>Ao acessar a Provi você concorda com:</TextTerms>
      <TextTerms>
        1. A consulta / compartilhamento com a <br />
        Provi de informações do SCR do Banco Central.
      </TextTerms>
      <TextTerms>
        2. Os{' '}
        <TextLinkExternal id="terms-of-service-button" target="_blank" rel="noreferrer" href={termsAndConditionsLink}>
          Termos de uso
        </TextLinkExternal>{' '}
        e a{' '}
        <TextLinkExternal id="privacy-policy-button" target="_blank" rel="noreferrer" href={privacyPolicyLink}>
          Política de Privacidade
        </TextLinkExternal>{' '}
        da Provi.
      </TextTerms>
      <TextTerms>3. Você concorda em receber comunicações da Provi via email e WhatsApp.</TextTerms>
    </ContainerTerms>
  )
}
