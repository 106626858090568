import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Content = styled.div`
  width: 100%;
  max-width: 600px;
`

export const Title = styled.div`
  font-family: 'CenturyGothicBold', sans-serif;
  font-size: 24px;
  line-height: 27px;
  margin-bottom: ${theme.variables.spacer * 3}px;
`

export const RedTitle = styled.div`
  font-family: 'CenturyGothicBold', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.pink900};
  margin-bottom: ${theme.variables.spacer * 1}px;
`

export const Text = styled.div`
  margin-bottom: 5px;

  font-size: 16px;
  line-height: 24px;
`

export const Bold = styled.span`
  margin-left: 10px;
  font-size: 16px;
  color: ${theme.colors.blue900};
  font-family: 'CenturyGothicBold', sans-serif;
`

export const Section = styled.div`
  margin-bottom: ${theme.variables.spacer * 3}px;
`
export const TextLinkExternal = styled.a`
  line-height: 2;
  text-align: left;
  color: ${theme.colors.blue900};
  text-decoration: underline;
  cursor: pointer;
`

export const TextTerms = styled.p`
  font-size: 9px;
  line-height: 15px;
  ${({ bold }) => bold && 'font-family: CenturyGothicBold, sans-serif;'}
  margin: 0;
  text-align: center;
`

export const ContainerTerms = styled.div`
  margin-top: 20px;
  p {
    text-align: left;
  }
  ${theme.breakpoints.down('md')} {
    margin: 0;
    p {
      text-align: center;
    }
  }
`
