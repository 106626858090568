/* eslint-disable no-undef */
import { useState, useEffect } from 'react'
import { showToast } from '~/components/toast'
import { ERRORS } from '../constants'

export const useScreenMeasure = () => {
  const [dimensions, setDimensions] = useState([window.innerWidth, window.innerHeight])

  useEffect(() => {
    window.addEventListener('resize', () => setDimensions([window.innerWidth, window.innerHeight]))
    return () => {
      window.removeEventListener('resize', () => setDimensions([window.innerWidth, window.innerHeight]))
    }
  }, [])

  return [dimensions[0] <= 800]
}

export const asyncLocalStorage = {
  setItem(key, value) {
    return Promise.resolve().then(() => {
      localStorage.setItem(key, value)
    })
  },
  getItem(key) {
    return Promise.resolve().then(() => {
      return localStorage.getItem(key)
    })
  }
}

export const capitalize = str => {
  if (!str) return false

  const strLower = str.toLowerCase()
  if (typeof strLower !== 'string') return ''

  return strLower
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

export const formatDate = d => {
  if (!d) return false
  // YYYY-MM-DD to DD/MM/YYYY

  const date = d.split('-')

  const years = date[0]
  const month = date[1]
  const days = date[2]

  return `${days}/${month}/${years}`
}

const formatValue2 = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2
})

export const formatValue = formatValue2.format

export const formatCpf = cpf => {
  if (!cpf) return false

  cpf = cpf.replace(/[^\d]/g, '')

  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export const removeSpaces = str => {
  if (!str) return
  return str.split(' ').join('')
}

export const removeDotsCpf = str => {
  if (!str) return
  return str.replace(/\D+/g, '')
}

export const toLower = str => {
  if (!str) return
  return str.toLowerCase()
}

export const formatPercent = str => {
  if (!str || typeof str !== 'string') return

  return str.split('.').join(',')
}

export const showAllToasts = error => {
  const errorsArray = error?.response?.data?.errors

  if (!['array', 'object'].includes(typeof errorsArray)) {
    return showToast(ERRORS.default)
  }

  for (const err of errorsArray) {
    showToast(err?.message || ERRORS.default)
  }
}
