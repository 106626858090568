import moment from 'moment'
import { STATES } from '~/constants'

export const validateFullName = fullName => {
  if (!fullName) {
    return false
  }
  const REGEX = /^[A-Za-zçáàâãéèêíïóôõöú ]+$/
  if (!REGEX.test(fullName)) {
    return false
  }
  fullName = fullName.trim()
  const names = fullName.split(' ')

  const firstName = names[0]
  let lastNames = names.filter((_name, index) => index > 0)

  if (!firstName) {
    return false
  }

  lastNames = lastNames.map(name => {
    if (name) {
      return name.trim()
    }

    return ''
  })

  if (!lastNames.length) {
    return false
  }

  return true
}

export const validateText = text => {
  if (!text) {
    return false
  }

  const REGEX = /^[A-Za-zçáàâãéèêíïóôõöúüÁÀÂÃÉÈÊÍÏÓÔÕÖÚÜ'ªº\- ]+$/
  if (!REGEX.test(text)) {
    return false
  }

  return true
}

export const validateState = state => {
  if (!state) {
    return false
  }

  if (state.length === 2) {
    const findState = STATES.filter(st => st.label.toUpperCase() === state.toUpperCase())
    return findState.length > 0
  }

  return false
}

export const validatePhone = phone => {
  const _value = Number(phone.replace(/[^\d]+/g, ''))
  const regex = /^(?:(55\d{2})|\d{2})[6-9]\d{8}$/

  if (!regex.test(_value) || typeof _value !== 'number' || String(_value).length !== 11) {
    return false
  }
  return true
}

export const validateDate = dateStr => {
  if (!dateStr) {
    return false
  }
  if (dateStr && dateStr.split('/').length !== 3) {
    return false
  }
  // moment(date, 'DD/MM/YYYY', true).isValid()
  const date = moment(dateStr, 'DD/MM/YYYY')
  const year = date.format('YYYY')
  const age = moment().diff(year, 'years')

  if (Number.isNaN(age)) {
    return false
  }

  return !(age < 18 || age > 70)
}
