import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Title = styled.h3`
  color: ${theme.colors.blue800};
  ${theme.breakpoints.down('sm')} {
    font-size: 20px;
    line-height: 30px;
    color: ${theme.colors.blue800};
    margin: 0;
  }
`

export const OptionsList = styled.div`
  margin-top: ${theme.variables.spacer * 3}px;
  margin-bottom: ${theme.variables.spacer * 3}px;

  max-width: 530px;

  ${theme.breakpoints.down('md')} {
    display: block;
  }
`

export const Option = styled.div`
  cursor: pointer;
  position: relative;
  margin-top: 30px;
  margin-right: 20px;
  box-shadow: 2px 1px 15px -9px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  padding: 20px;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
  border-radius: 20px;
  background-color: ${({ active }) => (active ? theme.colors.blue900 : theme.colors.white100)};
`

export const OptionTitle = styled.div`
  font-size: 16px;

  font-family: 'Teko';
  font-style: normal;
  font-weight: bold;
  text-align: left;

  letter-spacing: 0.1em;
  color: ${theme.colors.pink900};
`

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`

export const Bold = styled.span`
  font-family: CenturyGothicBold, sans-serif;
`

export const OptionText = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.colors.blue900};
`

export const OptionIcon = styled.div``
