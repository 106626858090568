import { useEffect, useState } from 'react'

export const useDocuments = (setIsLoading, setCurrent, goToPage) => {
  const [selectedOption, setSelectedOption] = useState()

  useEffect(() => {
    setCurrent(60, 3)
    setIsLoading(false)
  }, [setIsLoading])

  const handlePage = () => {
    setIsLoading(true)
    switch (selectedOption) {
      case 'rg':
        goToPage('/documento-pessoal/rg/frente')
        break
      case 'cnh':
        goToPage('/documento-pessoal/cnh')
        break
      default:
        break
    }
  }

  return [handlePage, setSelectedOption, selectedOption]
}
