import { useEffect, useState } from 'react'
import { showToast } from '~/components/toast'
import { getGuarantorInfo } from '~/services/api'
import { ERRORS } from '~/constants'

export const usePhone = (setIsLoading, setCurrent, checkToken, guarantorInfo) => {
  const [data, setData] = useState(undefined)

  useEffect(() => {
    setIsLoading(true)
    checkToken()
    setCurrent(40, 2)

    const getData = async () => {
      try {
        let response
        if (guarantorInfo && guarantorInfo.phone) {
          response = { data: { ...guarantorInfo } }
        } else {
          response = await getGuarantorInfo('/phone')
        }

        const { phone } = response.data

        setData({
          phone
        })

        setIsLoading(false)
      } catch (error) {
        showToast(ERRORS.default)
        setIsLoading(false)
      }
    }
    getData()
  }, [])

  return [data]
}
