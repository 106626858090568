import { useState } from 'react'

export const useModal = () => {
  const [next, setNext] = useState(false)

  const handleFirstStepButton = () => {
    setNext(true)
  }

  return [handleFirstStepButton, next]
}
