import { GuarantorListen } from '../screens/form/guarantor-listen'
import { SuccessInformation } from '../screens/success-information'
import { Introduction } from '../screens/introduction'
import { Resume } from '../screens/resume'
import { GuarantorSend } from '../screens/form/guarantor-send'
import { EmailValidation } from '../screens/form/email-validation'
import { ConfirmationPhone } from '../screens/form/confirmation-phone'

import { Documents } from '../screens/form/documents-info'
import { PersonalOption } from '../screens/form/personal-options'

import { PersonalDocumentRGFront } from '../screens/form/personal-document/rg/front'
import { PersonalDocumentRGBack } from '../screens/form/personal-document/rg/back'
import { PersonalDocumentCNH } from '../screens/form/personal-document/cnh'
import { BasicInfo } from '../screens/form/basic-info'
import { Cep } from '../screens/form/cep'
import { Email } from '../screens/form/email'
import { Phone } from '../screens/form/phone'

export const routes = [
  // {
  //   key: 0,
  //   path: '/404',
  //   component: NotFound,
  //   private: false,
  //   isFirstScreen: true,
  //   isHeader: false,
  //   isTemplate: false
  // },
  {
    key: 1,
    path: '/token',
    private: false,
    component: Introduction,
    isFirstScreen: true,
    isHeader: false,
    isTemplate: false
  },
  {
    key: 2,
    path: '/resumo',
    private: true,
    component: Resume
  },
  // {
  //   key: 3,
  //   path: '/confirmacao-dados',
  //   private: true,
  //   component: PersonalData
  // },
  {
    key: 3,
    path: '/infos-basicas',
    private: true,
    component: BasicInfo
  },
  {
    key: 4,
    path: '/cep',
    private: true,
    component: Cep
  },
  {
    key: 5,
    path: '/email',
    private: true,
    component: Email
  },
  {
    key: 6,
    path: '/verificacao-email',
    private: true,
    component: EmailValidation
  },
  {
    key: 7,
    path: '/telefone',
    private: true,
    component: Phone
  },
  {
    key: 8,
    path: '/verificacao-telefone',
    private: true,
    component: ConfirmationPhone
  },
  {
    key: 9,
    path: '/documentos',
    private: true,
    component: Documents
  },
  {
    key: 10,
    path: '/documento-pessoal',
    exact: true,
    private: true,
    component: PersonalOption
  },
  {
    key: 11,
    path: '/documento-pessoal/rg/frente',
    exact: true,
    private: true,
    component: PersonalDocumentRGFront
  },
  {
    key: 12,
    path: '/documento-pessoal/rg/costas',
    exact: true,
    private: true,
    component: PersonalDocumentRGBack
  },
  {
    key: 13,
    path: '/documento-pessoal/cnh',
    exact: true,
    private: true,
    component: PersonalDocumentCNH
  },
  {
    key: 16,
    path: '/avalistas',
    private: true,
    component: GuarantorListen,
    exact: true
  },
  {
    key: 17,
    path: '/avalistas/enviar',
    private: true,
    component: GuarantorSend
  },
  {
    key: 18,
    path: '/sucesso',
    private: true,
    component: SuccessInformation,
    isTemplate: false
  }
]
