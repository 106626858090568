import { useEffect, useState } from 'react'
import { configureScope } from '@sentry/browser'
import { history } from '~/navigation/history'
import { routes } from '~/navigation/routes'
import { getGuarantorInfo } from '~/services/api'
import { asyncLocalStorage } from '~/utils'

export const useSliderControl = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  })
}

export const useToken = () => {
  const [token, setToken] = useState(null)

  useEffect(() => {
    const checkStorage = async () => {
      const tokenStorage = localStorage.getItem('token')

      if (!tokenStorage && window.location.pathname.split('/')[1] !== 'token') {
        /* SITUAÇÃO ONDE ELE NÃO TEM O TOKEN NO LOCALSTORAGE E NÃO ESTA VINDO DO EMAIL - VAI TER QUE PEGAR O PUBLIC TOKEN TO LOCAL STORAGE NESSE CASO */
        history.push('/token')
      }
    }

    checkStorage()
  }, [token])

  const checkToken = async () => {
    const check = await asyncLocalStorage.getItem('token')

    if (!check) {
      history.push('/token')
    }
  }

  return [token, setToken, checkToken]
}

export const useHelpModal = () => {
  const [isShowModal, setIsShowModal] = useState(false)

  const handleModal = option => {
    setIsShowModal(option)
  }

  return [isShowModal, handleModal]
}

export const useSendingInfo = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingGlobal, setIsLoadingGlobal] = useState(true)
  const [count, setCount] = useState(1)

  const [modal, setModal] = useState(null)

  useEffect(() => {
    setCount(Number(count))
    setIsLoadingGlobal(false)
  }, [count])

  const goToPreviousScreen = async () => {
    setIsLoading(true)
    setModal(null)

    let emailVerified
    let phoneVerified

    const { pathname } = window.location
    if (pathname === '/documentos' || pathname === '/verificacao-telefone') {
      try {
        const response = await getGuarantorInfo('(component template)')
        const { emailVerified: email, phoneVerified: phone } = response.data
        emailVerified = email
        phoneVerified = phone
      } catch (error) {}
    }

    if (localStorage.getItem('asset-ref')) {
      localStorage.removeItem('asset-ref')
    }
    const active = routes.find(r => {
      return r.path === pathname
    })

    if (active) {
      const newRoute = routes.find(r => {
        if (active.path === '/avalistas') {
          return r.key === active.key - 6
        }

        if (active.path === '/documento-pessoal/cnh') {
          return r.key === active.key - 3
        }

        if (active.path === '/telefone' && !emailVerified) {
          return r.key === active.key - 2
        }

        if (active.path === '/documentos') {
          return r.key === active.key - 2
        }

        if (active.path === '/token') {
          setIsLoading(false)
          return r.key === active.key
        }

        return r.key === active.key - 1
      })

      if (newRoute) {
        history.push(newRoute.path)
      }
    } else {
      history.push('/token')
    }
  }

  const goToPage = async path => {
    setModal(null)
    if (path) history.push(path)
  }

  window.onpopstate = () => {
    window.history.go(1)
    goToPreviousScreen()
  }

  return [isLoading, goToPreviousScreen, setIsLoading, isLoadingGlobal, modal, setModal, goToPage]
}

export const useHeader = () => {
  const [current, setNewCurrent] = useState(0)
  const [refActive, setRefActive] = useState(1)
  const [options, setOptions] = useState([
    {
      ref: 1,
      title: 'PARTE 1',
      text: 'INTRODUÇÃO',
      active: true,
      complete: false
    },
    {
      ref: 2,
      title: 'PARTE 2',
      text: 'INFOS BÁSICAS',
      active: false,
      complete: false
    },
    {
      ref: 3,
      title: 'PARTE 3',
      text: 'DOCUMENTOS',
      active: false,
      complete: false
    }
  ])

  useEffect(() => {
    const optionsNew = options.map(item => {
      item.active = false
      item.complete = false

      if (item.ref < refActive) {
        item.complete = true
      }
      if (item.ref === refActive) {
        item.active = true
      }
      return item
    })
    setOptions(optionsNew)
  }, [current, refActive])

  const setCurrent = (curr, ref) => {
    setNewCurrent(curr)
    setRefActive(ref)
  }

  return [setCurrent, options, current]
}

export const useInfos = () => {
  const [creditRequestInfo, setCreditRequestInfo] = useState()
  const [guarantorInfo, setGuarantorInfo] = useState()
  const [ip, setIp] = useState()

  return [creditRequestInfo, setCreditRequestInfo, ip, setIp, guarantorInfo, setGuarantorInfo]
}

export const useSentryInfos = () => {
  const [infosSentry, setInfosSentry] = useState({ crid: '', name: '', email: '', phone: '' })

  useEffect(() => {
    configureScope(function(scope) {
      scope.setUser({
        id: infosSentry.crid,
        email: infosSentry.email,
        crid: infosSentry.crid,
        nome: infosSentry.name,
        phone: infosSentry.phone
      })
    })
  }, [infosSentry])

  return [infosSentry, setInfosSentry]
}
