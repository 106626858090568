import { useEffect, useState } from 'react'
import { theme } from '@provi/provi-components'
import { showToast } from '~/components/toast'
import { getGuarantorInfo, postSendEmail } from '~/services/api'
import { URL_EXTERNAL } from '~/constants'

export const useEmail = (setModal, setIsLoading, setCurrent, checkToken, handleModal, goToPage) => {
  const [data, setData] = useState()
  const [animation, setAnimation] = useState()

  useEffect(() => {
    setIsLoading(true)
    checkToken()
    setCurrent(33.33, 2)

    const sendEmail = async () => {
      try {
        const response = await postSendEmail({})

        if (response.status === 200) {
          showToast('Email enviado')
          setTimeout(() => {
            setAnimation(true)
          }, 5000)
        }
      } catch (error) {
        showToast('Não foi possivel enviar o email 😔. Espere alguns minutos antes de tentar novamente')
      }
    }

    const getData = async () => {
      try {
        // 1. verificar se guarantor tem email verified
        const response = await getGuarantorInfo('/validacao-email')
        const { email, emailVerified } = response.data

        // 2. se tiver, redireciona para /verificacao-telefone

        if (emailVerified) {
          goToPage('/telefone')
        } else {
          sendEmail()
        }

        // 3. se não tiver render screen
        setData(email)
      } catch (err) {
        //
      }

      setIsLoading(false)
    }
    getData()
  }, [setIsLoading])

  useEffect(() => {
    setModal({
      title: 'Não recebeu o email com o link de verificação?',
      text: [
        'Caso não tenha recebido o email com o link de verificação, e tem certeza que o email que digitou está correto, entre em conta com a gente.'
      ],
      textButton: 'Entendi',
      buttonFunction: () => handleModal(false),
      textLink: {
        color: theme.colors.blue900,
        text: 'Abrir chat',
        onClick: () => window.open(URL_EXTERNAL, '_blank')
      }
    })
  }, [setModal])

  const handleEmail = async () => {
    try {
      showToast('Reenviando email...')
      const response = await postSendEmail({ resend: true })

      if (response.status === 200) {
        showToast('Email reenviado')
        setTimeout(() => {
          setAnimation(true)
        }, 5000)
      }
    } catch (error) {
      showToast('Não foi possivel enviar o email 😔. Espere alguns minutos antes de tentar novamente')
    }
  }

  return [handleEmail, data, animation]
}
