import React, { useContext } from 'react'
import { SubHeaderDesktop, Container, Title } from '@provi/provi-components'
import { TemplateContext } from '~/components/template/context'
import { Modal } from './modal'
import { IconVideo } from '~/assets/svg/videocam'
import { IconPicture } from '~/assets/svg/picture'
import { OptionsList, Option, OptionIcon, OptionTitle, Section } from './style'
import { useGuarantorListen } from './hooks'

export const GuarantorListen = () => {
  const { handleModal, isMobile, setCurrent, setModal, setIsLoading, goToPage } = useContext(TemplateContext)

  const [isShowModal, setIsShowModal, handlePage, handleModalButton, asset, name] = useGuarantorListen(
    setModal,
    setIsLoading,
    setCurrent,
    handleModal,
    goToPage
  )

  return (
    <>
      {isShowModal && <Modal name={name} asset={asset} handleModalButton={handleModalButton} setIsShowModal={setIsShowModal} />}
      <Container>
        {isMobile ? (
          <Title text="Escolha uma das duas opções abaixo" />
        ) : (
          <SubHeaderDesktop isShowButtonHelpModal title="Escolha uma das duas opções abaixo" handleModal={handleModal} />
        )}

        <OptionsList>
          <Option cy="guarantor-video" onClick={() => handlePage('video')}>
            <Section>
              <OptionTitle>VÍDEO SELFIE</OptionTitle>
              <OptionIcon>
                <IconVideo />
              </OptionIcon>
            </Section>
          </Option>

          <Option cy="guarantor-photo" onClick={() => handlePage('photo')}>
            <Section>
              <OptionTitle>FOTO SELFIE</OptionTitle>
              <OptionIcon>
                <IconPicture />
              </OptionIcon>
            </Section>
          </Option>
        </OptionsList>
      </Container>
    </>
  )
}
