import { useState, useEffect } from 'react'
import axios from 'axios'
import smartlook from 'smartlook-client'
import { authToken, getAccessToken, getCreditRequestInfo } from '~/services/api'
import { showToast } from '~/components/toast'
import { asyncLocalStorage, removeSpaces, removeDotsCpf } from '~/utils'
import { logger } from '~/helpers/logger'
import { ERRORS, ROOT_URL } from '~/constants'

export const useIntroduction = (setIsLoading, setInfosSentry, setIp, goToPage) => {
  const [isShowModal, setIsShowModal] = useState(false)
  const [username, setUsername] = useState(false)
  const [urlToken, setUrlToken] = useState('')

  useEffect(() => {
    setIsLoading(true)

    const getIp = async () => {
      try {
        const { data } = await axios.get('https://api.ipify.org/?format=json')

        setIp(data.ip)
      } catch (error) {
        logger({ error, screen: '/token', ref: 'ipify' })
      }
    }
    getIp()

    let queryToken
    const checkTokenInUrl = async () => {
      const urlParams = new URLSearchParams(window.location.search)
      queryToken = urlParams.get('token')

      if (queryToken) {
        // se ele entrou aqui é pq tem o query na url, ou seja não tem token em nenhum lugar
        await asyncLocalStorage.setItem('token', queryToken)
        const queryScreen = urlParams.get('screen')

        const responseGuarantorInfo = await getCreditRequestInfo()

        const { isNewSdk } = responseGuarantorInfo?.data

        if (isNewSdk) {
          window.location.href = `${ROOT_URL.creditSdk}/avalista/token/${queryToken}/${queryScreen}`

          return
        }

        if (queryScreen === 'phone') {
          setIsLoading(true)
          goToPage('/telefone')
        }
        if (queryScreen === 'default') {
          setIsLoading(true)
          goToPage('/resumo')
        }
      }
      setIsLoading(false)
    }

    checkTokenInUrl()

    let tokenInUrl = window.location.pathname.split('/')[2]
    if (tokenInUrl) {
      localStorage.setItem('public_token', tokenInUrl)
    } else if (localStorage.getItem('public_token')) {
      tokenInUrl = localStorage.getItem('public_token')
    }
    setUrlToken(tokenInUrl)

    const getInfos = async () => {
      try {
        const response = await getAccessToken(tokenInUrl && tokenInUrl.toUpperCase())
        const { name, crid, isNewSdk } = response.data

        if (isNewSdk) window.location.href = `${ROOT_URL.creditSdk}/avalista/${tokenInUrl}`

        setInfosSentry(props => ({ ...props, crid }))
        if (name) setUsername(name)
      } catch (error) {
        showToast(ERRORS.introduction.token)
        setUsername('')
      }
      setIsLoading(false)
    }
    if (!queryToken) {
      getInfos()
    }
  }, [])

  return [username, isShowModal, setIsShowModal, urlToken]
}

export const useForm = (setIsLoading, setToken, urlToken, goToPage) => {
  const [value, setValue] = useState('')
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isShowError, setIsShowError] = useState(false)

  const onSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)
    try {
      if (value && urlToken) {
        const inputToken = removeSpaces(value.toLowerCase())
        const response = await authToken(removeDotsCpf(inputToken), urlToken)

        const { token, crid } = response.data

        try {
          smartlook &&
            smartlook.identify('GuarantorCrid', {
              CRID: crid || 'Não encontrado'
            })
        } catch (error) {
          // segue o fluxo e ignora o erro
        }
        setToken(token)
        await asyncLocalStorage.setItem('token', token)

        goToPage('/resumo')
      } else {
        setTimeout(() => {
          showToast(ERRORS.introduction.auth)
          setIsLoading(false)
        }, 500)
      }
    } catch (err) {
      showToast(err.message || ERRORS.introduction.cpfInvalid)
      setIsLoading(false)
    }
  }

  const onFormChange = e => {
    if (!e) return
    const token = e.target.value
    setValue(token)

    setIsButtonDisabled(!(token && token.length === 14))
    setIsShowError(!(token && token.length === 14))
  }

  const onBlur = () => {
    if (!value) {
      setIsShowError(true)
    }
  }

  return [onSubmit, onFormChange, isButtonDisabled, isShowError, value, onBlur]
}
