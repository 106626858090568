import React, { useContext } from 'react'
import { Title, SubHeaderDesktop, Container, SubTitle } from '@provi/provi-components'
import { TemplateContext } from '~/components/template/context'
import { FormFormik } from './form'
import { useBasicInfo } from './hooks'
import { Bold } from './style'

export const BasicInfo = () => {
  const {
    handleModal,
    isMobile,
    setCurrent,
    setIsLoading,
    isLoading,
    checkToken,
    setInfosSentry,
    guarantorInfo,
    setGuarantorInfo,
    goToPage
  } = useContext(TemplateContext)

  const [data] = useBasicInfo(setIsLoading, setCurrent, checkToken, setInfosSentry, guarantorInfo)

  return (
    <Container>
      {!isLoading && data && (
        <>
          {isMobile ? (
            <Title cy="title" text="Confirme suas informações" />
          ) : (
            <SubHeaderDesktop cy="title" title="Confirme as suas informações" handleModal={handleModal} />
          )}

          <SubTitle
            text={
              <>
                Os dados abaixo devem ser de{' '}
                <Bold>{data.fullName && data.fullName !== 'Null Null' ? data.fullName : 'Avalista'}</Bold>
              </>
            }
          />

          <FormFormik data={data} setIsLoading={setIsLoading} setGuarantorInfo={setGuarantorInfo} goToPage={goToPage} />
        </>
      )}
    </Container>
  )
}
