import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, LoadingProvi, SubHeader, Header, ButtonBackDesktop, theme } from '@provi/provi-components'
import { TemplateContext } from './context'
import { View, TextModal, Left, Right } from './style'

const URL_EXTERNAL = 'https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20?'

export const Template = props => {
  // eslint-disable-next-line react/prop-types
  const { isHeader, isFirstScreen, children } = props
  const {
    isLoading,
    goToPreviousScreen,
    isLoadingGlobal,
    isShowModal,
    handleModal,
    isMobile,
    modal,
    current,
    options
  } = useContext(TemplateContext)

  return (
    <>
      {isLoading && <LoadingProvi />}

      {isShowModal && modal && (
        <Modal
          title={modal.title}
          onDismiss={() => handleModal(false)}
          footer={
            modal.textButton && (
              <Button
                marginHorizontal={0}
                type="button"
                text={modal.textButton}
                onClick={() => (modal.buttonFunction ? modal.buttonFunction() : window.open(URL_EXTERNAL, '_blank'))}
                textLink={{
                  color: theme.colors.blue900,
                  text: modal && modal.textLink && modal.textLink.text,
                  onClick: () => modal && modal.textLink && modal.textLink.onClick && modal.textLink.onClick()
                }}
              />
            )
          }
        >
          {modal.text.map((text, idx) => (
            <TextModal key={idx}>{text}</TextModal>
          ))}
        </Modal>
      )}

      {!isLoadingGlobal && (
        <View>
          <Left>
            {isHeader && <Header current={current} total={100} options={options} />}
            {!isFirstScreen && !isMobile && <ButtonBackDesktop functionBackButton={goToPreviousScreen} />}
            {isMobile && !isFirstScreen && (
              <SubHeader
                isShowButtonHelpModal={!!modal}
                functionBackButton={goToPreviousScreen}
                functionHelpModal={() => handleModal(true)}
              />
            )}
            {children}
          </Left>
          {!isMobile && <Right />}
          {/* <WhatsIcon /> */}
        </View>
      )}
    </>
  )
}

Template.defaultProps = {
  isHeader: true,
  isFirstScreen: false
}

Template.propTypes = {
  isHeader: PropTypes.bool,
  isFirstScreen: PropTypes.bool
}
